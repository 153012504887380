import React, {useEffect, useState, useRef} from "react";
import {useAuthentification} from "../../Context/AuthContext";

import simpleFetch from "../../data/simpleFetch";

import {
    Form,
    SelectPicker,
    InputNumber,
    Button,
    Schema,
    Message,
} from "rsuite";
import {useNavigate} from "react-router-dom";

const PaiementLinksView = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);

    const [formValue, setFormValue] = useState({
        user: null,
        amount: null,
    });
    const {getToken} = useAuthentification();
    useEffect(() => {
        const token = getToken();
        simpleFetch("/users", "GET", undefined, token).then((data) => {
            console.log(data);
            setUsers(data?.map((user) => ({label: user.email, value: user.id})));
        });
    }, []);

    const model = Schema.Model({
        user: Schema.Types.NumberType().isRequired(
            "Merci de séléctionner un utilisateur"
        ),
        amount: Schema.Types.NumberType().isRequired("Merci de saisir un montant"),
    });
    const formRef = useRef();
    const [orderId, setOrderId] = useState(null);

    function createOrder() {
        if (formRef.current.check()) {
            simpleFetch(
                "/orders/payment-link",
                "POST",
                {
                    userId: formValue.user,
                    price: parseFloat(formValue.amount).toFixed(2),
                },
                getToken()
            ).then((data) => {
                console.log(data);

                setOrderId(data.id);
            });
        }
    }

    return (
        <div>
            {orderId && (
                <Message
                    style={{marginBottom: 10}}
                    showIcon
                    type="success"
                    header="Commande créee !"
                >
                    La commande à été créée. L'utilisateur peut dès maintenant la payer
                    depuis l'application : E-Shop - Panier - Commandes en attente -
                    Commande n° {orderId}
                    <br/>
                    Il a aussi reçu un lien de paiement sur son e-mail. Ce lien redirige
                    sur la page de l'application.
                    <br/>
                    <Button
                        appearance="ghost"
                        style={{marginTop: 10}}
                        onClick={() => navigate(`/orders/${orderId}`)}
                    >
                        Ouvrir
                    </Button>
                </Message>
            )}
            <h1 className="title">Lien de paiements</h1>
            <Form
                ref={formRef}
                model={model}
                value={formValue}
                onChange={(values) => setFormValue(values)}
            >
                <Form.Group controlId="user">
                    <Form.ControlLabel>Utilisateur</Form.ControlLabel>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Form.Control
                            style={{width: 320}}
                            name="user"
                            accepter={SelectPicker}
                            placeholder="Séléctionner un utilisateur"
                            locale={{
                                searchPlaceholder: "Rechercher",
                            }}
                            data={users}
                            block
                        />
                        <Form.HelpText tooltip>
                            Une commande ayant aucun produit sera créée pour cet utilisateur. Il
                            est necessaire que l'utilisateur ai un compte sur l'application.
                        </Form.HelpText>
                    </div>
                </Form.Group>
                <Form.Group controlId="amount">
                    <Form.ControlLabel>Montant en Euro</Form.ControlLabel>
                    <Form.Control
                        style={{width: 320}}
                        name="amount"
                        accepter={InputNumber}
                        placeholder="Indiquer un montant"
                        min={0.01}
                        block
                    />
                </Form.Group>
                <Button className="btn-main" onClick={createOrder}>
                    Créer la commande
                </Button>
            </Form>
        </div>
    );
}

export default PaiementLinksView;
