import React, { createContext, useContext, useState, useEffect } from "react";
import simpleFetch from "../data/simpleFetch";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

const AuthContext = createContext({
  isAuthentificated: false,
  login: (email, password) => {},
  logout: () => {},
  getToken: () => {},
});

function AuthProvider({ children }) {
  function createNotification(type) {
    console.log(type);
    switch (type) {
      case "credentials":
        toast.error("Mot de passe ou adresse email invalide");
        break;
      case "network":
        toast.error("Impossible de joindre le serveur.");
        break;
      case "noPermission":
        toast.error("Vous n'avez pas les droits nécessaires.");
        break;
      case "info":
        toast.info("Info message");
        break;
      default:
        break;
    }
  }

  const [isAuthentificated, setisAuthentificated] = useState(false);
  const [token, setToken] = useState();
  const [refreshToken, setRefreshToken] = useState();

  useEffect(() => {
    try {
      const refreshToken = window.sessionStorage.getItem("refreshToken");
      var decoded = jwt_decode(refreshToken);
      const date = Date.now();
      const date2 = decoded.exp * 1000;
      console.log("DATES", date, date2, date - date2);
      if (date2 - date < 0) {
        console.log("DECONNECTE");
        logout();
      } else {
        console.log(decoded);
        console.log(refreshToken);
        simpleFetch("/auth/refresh", "POST", {
          refreshToken,
        }).then((response) => {
          console.log("refresh", response);

          if (response?.accessToken) {
            console.log("CONNECTE", refreshToken, response.accessToken);
            setRefreshToken(refreshToken);
            setToken(response.accessToken);
            setisAuthentificated(true);
          } else {
            logout();
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  async function login(email, password) {
    console.log(email, password);
    const response = await simpleFetch("/auth/login", "POST", {
      email,
      password,
    });
    if (response?.accessToken) {
      const infos = jwt_decode(response.accessToken);

      console.log(response);
      if (infos?.roles?.includes("admin")) {
        window.sessionStorage.setItem("refreshToken", response.refreshToken);
        setToken(response.accessToken);
        setRefreshToken(response.refreshToken);
        setisAuthentificated(true);
      } else {
        logout();
        console.warn("Unable to login, user is not an admin");
        createNotification("noPermission");
      }
    } else {
      if (response?.statusCode === 401) {
        createNotification("credentials");
      } else {
        createNotification("network");
      }
    }
  }
  async function logout() {
    console.warn("logout");
    window.sessionStorage.removeItem("refreshToken");
    setRefreshToken();
    setToken();
    setisAuthentificated(false);
  }

  async function refreshTheToken() {
    const date = Date.now();
    var decoded = jwt_decode(token);
    const date2 = decoded.exp * 1000;

    if (date2 - date < 300000) {
      const response = await simpleFetch("/auth/refresh", "POST", {
        refreshToken,
      });
      console.log("refresh", response);

      if (response?.accessToken) {
        setToken(response.accessToken);
      } else {
        logout();
      }
    }
  }

  function getToken() {
    refreshTheToken();
    return token;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthentificated,
        login,
        logout,
        getToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
const useAuthentification = () => useContext(AuthContext);

export { AuthProvider, useAuthentification };
