import React, {useState} from "react";
import {Input, Form, Button} from "rsuite";
import {useAuthentification} from "../../Context/AuthContext";
import simpleFetch from "../../data/simpleFetch";
import {toast} from "react-toastify";
import {useEffect} from "react";
import FormGroup from "rsuite/esm/FormGroup";

const NotificationView = () => {
    const {getToken} = useAuthentification();

    useEffect(() => {
        const token = getToken();
        if (!formValue?.token) {
            simpleFetch("/users/profile", "GET", undefined, token).then((data) => {
                if (data?.expoPushToken) {
                    toast.success(
                        "Votre token de notification à été rentré automatique, vous pouvez envoyer votre notification à vous même avant de l'envoyer à tout le monde !"
                    );
                    setFormValue({...formValue, token: data.expoPushToken});
                } else {
                    toast.error(
                        "Vous n'avez pas de token de notification associé à votre compte. Essayez de vous connecter à l'application avec le même compte afin de l'associer. Vous ne pourrez pas tester vos notifications tant que vous n'aurez pas de token associé."
                    );
                }
            });
        }
    }, []);

    const [formValue, setFormValue] = useState({
        title: "",
        content: "",
        token: "",
    });

    function sendToOne() {
        console.log("SENT TO ONE");

        simpleFetch(
            "/notifications/send-test",
            "POST",
            {
                title: formValue.title,
                message: formValue.content,
                pushToken: formValue.token,
            },
            getToken(),
            true
        ).then((data) => {
            console.log(data);
            toast.success("Notification envoyée");
            toast.info(
                "Vous ne l'avez pas reçu ? Deconnectez vous de l'application et reconnectez vous dessus avec votre compte admin. Rechargez cette page. Verifiez que vous avez bien autorisé les notifications sur votre téléphone. Les notifications peuvent arriver avec un délai.",
                {
                    autoClose: 20000,
                }
            );
        });
    }

    function sendToAll() {
        console.log("SENT TO ALL");
        toast.info(
            "Notification en cours d'envoi... N'appuyez pas sur le bouton plusieurs fois, vos utilisateurs recevraient plusieurs notifications"
        );
        simpleFetch(
            "/notifications/send",
            "POST",
            {
                title: formValue.title,
                message: formValue.content,
            },
            getToken(),
            true
        ).then((data) => {
            console.log(data);
            toast.success(
                "Notification envoyée à tous les utilisateurs ayant autorisé les notifications !"
            );
            toast.info(
                "Vous ne l'avez pas reçu ? Deconnectez vous de l'application et reconnectez vous dessus avec votre compte admin. Rechargez cette page. Verifiez que vous avez bien autorisé les notifications sur votre téléphone. Les notifications peuvent arriver avec un délai.",
                {
                    autoClose: 20000,
                }
            );
        });
    }

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <h1 className="title">Notifications</h1>
            <h5 className="subtitle">Envoie à un utilisateur (Tests uniquement)</h5>
            <FormGroup style={{maxWidth: 500, padding:10}}>
                <Input
                    placeholder="Titre"
                    value={formValue?.title}
                    className="default-input"
                    onChange={(value) => setFormValue({...formValue, title: value})}
                />
                <Input
                    placeholder="Contenu"
                    value={formValue?.content}
                    className="default-input"
                    onChange={(value) => setFormValue({...formValue, content: value})}
                />
                <Input
                    placeholder="Token de l'utilisateur"
                    value={formValue?.token}
                    className="default-input"
                    onChange={(value) => setFormValue({...formValue, token: value})}
                />
                <Button className="btn-main" onClick={sendToOne}>Envoyer</Button>
            </FormGroup>


            <h5 className="subtitle">Envoie à tout les utilisateurs</h5>
            <FormGroup style={{maxWidth: 500, padding:10}}>
                <Input
                    placeholder="Titre"
                    value={formValue?.title}
                    className="default-input"
                    onChange={(value) => setFormValue({...formValue, title: value})}
                />
                <Input
                    placeholder="Contenu"
                    className="default-input"
                    value={formValue?.content}
                    onChange={(value) => setFormValue({...formValue, content: value})}
                />
                <Button className="btn-main" onClick={sendToAll}>Envoyer</Button>
            </FormGroup>
        </div>
    );
}

export default NotificationView;
