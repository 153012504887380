import React, { forwardRef } from "react";
import "./styleInvoice.css";
import banniere from "../assets/banniere.png";
const InvoiceComponent = forwardRef(({ order, price, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className="invoiceTicket"
      style={{
        padding: 10,
        width: 430,
      }}
    >
      <img src={banniere} style={{ width: 420 }} alt="logo" />
      <h1 style={{ fontSize: 15, lineHeight: 1 }}>PHARMACIEL</h1>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            //   backgroundColor: "red",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 50,
          }}
        >
          40 rue des Acacias
          <br />
          75017 Paris
          <br />
          TEL : 01 43 80 18 26
        </div>
        <div
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          Le : {new Date().toLocaleDateString()}
          <br />
          Commandé le : {new Date(order.createdAt).toLocaleDateString()}
        </div>
      </div>
      <h3 style={{ fontSize: 14, lineHeight: 1.2 }}>Informations </h3>
      <p>
        Nom : {order.user.lastName || "-"}
        <br />
        Prénom : {order.user.firstName || "-"}
        <br />
        Email : {order.user.email || "-"}
        <br />
        Téléphone : {order.user.phone || "-"}
      </p>
      <h3 style={{ fontSize: 14, lineHeight: 1.2 }}>
        Informations de livraison
      </h3>
      <p>
        {order.address?.line1 && order.address.line1}
        {order.address?.line1 && <br />}
        {order.address?.line2 && order.address.line2}
        {order.address?.line2 && <br />}
        {order.address.postalCode} {order.address.city} {order.address.country}
        {(order.address.postalCode ||
          order.address.city ||
          order.address.country) && <br />}
        Indications : {order.address?.indications || "-"}
      </p>
      {order.ordersToProducts.length === 0 ? (
        "Aucun produit. Facture de reste à payer."
      ) : (
        <table style={{ backgroundColor: "white", width: 420 }}>
          <thead>
            <tr style={{ textAlign: "left" }}>
              <th>Produit</th>
              <th>Qté</th>
              <th style={{ minWidth: 70 }}>A régler</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {order.ordersToProducts?.map(({ amount, product }) => {
              return (
                <tr style={{ flex: 1 }}>
                  <td style={{ marginRight: 10 }}>{product.name}</td>
                  <td>{amount}</td>
                  <td>{product.price.toFixed(2)}</td>
                  <td>{(product.price * amount).toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {order?.deliveryMode === "empty" ? (
        <h4 style={{ fontSize: 13, lineHeight: 1.2 }}>
          Frais restant à payer {order?.priceIfEmpty}
        </h4>
      ) : (
        <>
          <h4 style={{ fontSize: 13, lineHeight: 1.2 }}>
            Frais de livraison {price.deliveryFee}
          </h4>
          <h4 style={{ fontSize: 13, lineHeight: 1.2 }}>
            Réduction {price.reduction}
          </h4>
        </>
      )}
      <h3 style={{ fontSize: 14, lineHeight: 1.2 }}>
        Net à payer en EUR{" "}
        {price.orderTotal > 0 ? price.orderTotal : order?.priceIfEmpty}
      </h3>
    </div>
  );
});

export default InvoiceComponent;
