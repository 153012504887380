import {Outlet, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import SidebarNavigation from "./Components/SidebarNavigation";
import {useCalling} from "./Context/CallContext";
import TwilioView from "./routes/telepharmacie/TwilioView/TwilioView";

import "./App.scss";

function App() {
    const {communicationOpen} = useCalling();
    const [showMenu, setShowMenu] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setShowMenu(false);
    }, [location]);

    return (
        <>
            <div className="mobile-banner">
                <button className="menu-btn" onClick={() => setShowMenu(!showMenu)}>
                    <img className="icon" src={showMenu ? "/close.svg" : "/menu.svg"} alt={"afficher le menu"}/>
                </button>
                <div style={{ height:"100%"}}>
                    <img style={{height:"100%"}} src={"/banniere.png"} alt={""} role={"presentation"}/>
                </div>
            </div>
            <div style={{display: "flex"}}>
                <div style={showMenu ? {left:0}:{}} className="sidebar-wrapper">
                    <SidebarNavigation/>
                </div>

                <div className="main-section">
                    {communicationOpen &&
                        <TwilioView/>}

                    <Outlet/>
                </div>
            </div>
        </>
    );
}

export default App;
