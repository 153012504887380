import React, { useState, useEffect } from "react";
import ConfirmationModal from "../../Components/ConfirmationModal";
import { useAuthentification } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import { Input, Uploader, RadioGroup, Radio, SelectPicker } from "rsuite";
import { FaCameraRetro } from "react-icons/fa";
import FormGroup from "rsuite/esm/FormGroup";
import simpleFetch from "../../data/simpleFetch";
import simpleFetchImage from "../../data/simpleFetchImage";

function EditCategoryModal({
  isOpen,
  setOpen,
  categoryData,
  callBackOnModify,
  categoryId,
}) {
  console.log(categoryData, categoryId);
  const { getToken } = useAuthentification();
  const [isLoading, setIsLoading] = useState(false);
  const [editValues, setEditValues] = useState(categoryData || {});

  useEffect(() => {
    if (isOpen && !categoryData && categoryId) {
      setIsLoading(true);
      const token = getToken();
      console.log(`/categories/${categoryId}`);
      simpleFetch(`/categories/${categoryId}`, "GET", null, token)
        .then((cat) => {
          console.log("CAT", cat);
          setEditValues(cat);
          setIsLoading(false);
        })
        .catch((err) => console.warn(err));
    }
    if ((categoryData || categoryId) && isOpen) {
      const token = getToken();
      simpleFetch("/categories/medipim/categories", "GET", null, token)
        .then((res) => {
          if (res?.length > 0) {
            setCatMedipimList(
              res?.map((category) => ({
                label: category.name,
                value: category.id,
              }))
            );
          }
        })
        .catch((err) => {
          console.warn(err);
        });
      simpleFetch("/categories/medipim/brands", "GET", null, token)
        .then((res) => {
          if (res?.length > 0) {
            setBrandMedipimList(
              res?.map((category) => ({
                label: category.name,
                value: category.id,
              }))
            );
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  }, [isOpen, categoryId]);
  const [catmedipimList, setCatMedipimList] = useState([]);
  const [brandmedipimList, setBrandMedipimList] = useState([]);
  return (
    <ConfirmationModal
      title={"Modification d'une catégorie"}
      open={isOpen}
      setOpen={setOpen}
      loading={isLoading}
      acceptText={"Modifier"}
      acceptAction={() => {
        setIsLoading(true);
        if (!editValues?.name) {
          toast.error("Veuillez remplir tous les champs", {
            autoClose: 2000,
          });
          setIsLoading(false);
          return;
        }

        const token = getToken();

        const dataToSend = {
          name: editValues?.name,
          type: editValues?.type || null,
          medipimCategoryId: editValues?.medipimCategoryId || null,
          medipimBrandId: editValues?.medipimBrandId || null,
          image: typeof editValues?.image === "string" ? editValues?.image : "",
          image2:
            typeof editValues?.image2 === "string" ? editValues?.image2 : "",
        };
        console.log("dataToSend", dataToSend);
        simpleFetch("/categories/" + categoryId, "PATCH", dataToSend, token)
          .then((res) => {
            console.log("CREATION", res);
            // check if image to upload
            console.log("IMAGE", editValues?.image);
            console.log("SENDING IMAGE");
            if (
              typeof editValues?.image !== "string" &&
              editValues?.image?.length > 0 &&
              editValues?.image[0]?.blobFile
            ) {
              const formData = new FormData();
              formData.append(
                "image",
                editValues?.image[0]?.blobFile,
                editValues?.image[0]?.blobFile?.name
              );
              simpleFetchImage(
                `/categories/${categoryId}/image`,
                "POST",
                formData,
                token
              )
                .then((res) => {
                  console.log("IMAGE", res);
                })
                .catch((err) => {
                  setIsLoading(false);

                  toast.error("Une erreur est survenue");

                  console.warn(err);
                  return;
                });
            }
            if (
              typeof editValues?.image2 !== "string" &&
              editValues?.image2?.length > 0 &&
              editValues?.image2[0]?.blobFile
            ) {
              const formData = new FormData();
              formData.append(
                "image",
                editValues?.image2[0]?.blobFile,
                editValues?.image2[0]?.blobFile?.name
              );
              simpleFetchImage(
                `/categories/${categoryId}/image2`,
                "POST",
                formData,
                token
              )
                .then((res) => {
                  console.log("IMAGE", res);
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error("Une erreur est survenue");
                  console.warn(err);
                  return;
                });
            }
          })
          .finally(() => {
            setOpen(false);
            setIsLoading(false);

            toast.success(
              "Catégorie modifiée avec succès. Actualisation des catégories..."
            );
            setTimeout(() => {
              callBackOnModify();
            }, 1000);
          });
      }}
    >
      <FormGroup style={{ flexDirection: "column" }}>
        <div>
          <h5>Nom de catégorie :</h5>

          <Input
            placeholder="Nom de la catégorie"
            style={{ maxWidth: 400, marginRight: 10 }}
            onChange={(value) => setEditValues({ ...editValues, name: value })}
            value={editValues?.name}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <h5>Image nom de catégorie :</h5>
          <Uploader
            renderFileInfo={(file, fileElement) => {
              return (
                <div>
                  <span>File Name: {file.name}</span>
                  <p>File URL: {file.url}</p>
                </div>
              );
            }}
            listType="picture"
            fileList={
              editValues?.image
                ? typeof editValues?.image === "string"
                  ? [
                      {
                        url: editValues?.image,
                        name: `${editValues?.id}.png`,
                        fileKey: 1,
                      },
                    ]
                  : editValues?.image?.length >= 1
                  ? [editValues?.image[0]]
                  : []
                : []
            }
            onRemove={() => {
              setEditValues({
                ...editValues,
                image: "",
              });
            }}
            autoUpload={false}
            onChange={(img) => {
              console.log("IMG LIST", img);
              setEditValues({
                ...editValues,
                image: img.length >= 1 ? [img[img.length - 1]] : [],
              });
            }}
            accept={["image/png", "image/jpeg"]}
          >
            <button>
              <FaCameraRetro />
            </button>
          </Uploader>
        </div>
        <div style={{ marginTop: 20 }}>
          <h5>Image de catégorie :</h5>
          <Uploader
            renderFileInfo={(file, fileElement) => {
              return (
                <div>
                  <span>File Name: {file.name}</span>
                  <p>File URL: {file.url}</p>
                </div>
              );
            }}
            listType="picture"
            fileList={
              editValues?.image2
                ? typeof editValues?.image2 === "string"
                  ? [
                      {
                        url: editValues?.image2,
                        name: `${editValues?.id}.png`,
                        fileKey: 1,
                      },
                    ]
                  : editValues?.image2?.length >= 1
                  ? [editValues?.image2[0]]
                  : []
                : []
            }
            onRemove={() => {
              setEditValues({
                ...editValues,
                image2: "",
              });
            }}
            autoUpload={false}
            onChange={(img) => {
              console.log("IMG LIST", img);
              setEditValues({
                ...editValues,
                image2: img.length >= 1 ? [img[img.length - 1]] : [],
              });
            }}
            accept={["image/png", "image/jpeg"]}
          >
            <button>
              <FaCameraRetro />
            </button>
          </Uploader>
        </div>
        <div>
          <RadioGroup
            inline
            name="radioList"
            value={editValues?.type}
            onChange={(value) => {
              setEditValues({ ...editValues, type: value });
            }}
          >
            <Radio value="pharmacy">Pharmacie</Radio>
            <Radio value="cosmetic">Cosmétique et beauté</Radio>
            <Radio value="food">Compléments alimentaires</Radio>
          </RadioGroup>
        </div>
        <h5>Lien avec une marque ou une catégorie Medipim</h5>
        Associez une catégorie et/ou une marque Médipim à cette catégorie.
        <SelectPicker
          placeholder="Séléctionner une catégorie Médipim"
          name="category"
          locale={{
            searchPlaceholder: "Rechercher",
          }}
          data={catmedipimList}
          block
          value={editValues?.medipimCategoryId}
          onChange={(value) => {
            setEditValues({ ...editValues, medipimCategoryId: value });
          }}
        />
        <SelectPicker
          placeholder="Séléctionner une marque Médipim"
          name="brands"
          locale={{
            searchPlaceholder: "Rechercher",
          }}
          data={brandmedipimList}
          block
          value={editValues?.medipimBrandId}
          onChange={(value) => {
            setEditValues({ ...editValues, medipimBrandId: value });
          }}
        />
      </FormGroup>
    </ConfirmationModal>
  );
}

export default EditCategoryModal;
