import { APIURL } from "../env";

async function simpleFetchImage(endPoint, method, formdata, token) {
  let options = {
    method: method,
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
    body: formdata,
  };

  console.log("A simple fetch image was made");
  try {
    const response = await fetch(APIURL + endPoint, options);
    // if (rawResponse !== undefined) {
    //   return response.text();
    // }

    const json = await response.text();
    // console.log("RESPONSE", json);
    return json;
  } catch (e) {
    return e;
  }
}

export default simpleFetchImage;
