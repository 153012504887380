import React, {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {
    Whisper,
    Tooltip,
    Schema,
    Modal,
    Button,
    Form,
    ButtonToolbar,
    Checkbox,
    RadioGroup,
    Radio,
    SelectPicker,
    InputNumber,
    Uploader,
    Message,
} from "rsuite";
import {FaCameraRetro} from "react-icons/fa";
import InfoRoundIcon from "@rsuite/icons/InfoRound";

import {toast} from "react-toastify";
import "./products.css";
import simpleFetch from "../../data/simpleFetch";
import {useAuthentification} from "../../Context/AuthContext";
import {TiArrowLeftThick} from "react-icons/ti";
import EditCategoryModal from "../categories/EditCategoryModal";

import JoditEditor from "jodit-react";
import categoryType from "../../data/categoriesType";
import simpleFetchImage from "../../data/simpleFetchImage";

function AddProductModal({open, setOpen, ...props}) {
    const navigate = useNavigate();
    const [productId, setProductId] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const [activeItem, setActiveItem] = useState("description");
    const {getToken} = useAuthentification();
    const [cip13, setCip13] = useState("");

    const handleClose = () => {
        navigate("");
        setOpen(false);
        setCip13();
        setActiveItem("description");
        setFormDataBaseValue(defaultValue);
        setFormMedipimValue();
    };

    async function getProduct(id) {
        const token = getToken();
        const product = await simpleFetch(
            "/products/" + id,
            "GET",
            undefined,
            token
        );
        return product;
    }

    async function searchFromCIP13(cip13) {
        toast.info(
            "Recherche du produit dans la base de donnée et sur MEDIPIM, ceci peut prendre quelque secondes."
        );
        setFormDataBaseValue(defaultValue);
        setFormMedipimValue();
        if (cip13) {
            console.info(`recherche du produit ${cip13}`);
            const token = getToken();
            const resultMedipim = await simpleFetch(
                "/products/medipim/" + cip13,
                "GET",
                undefined,
                token
            );
            const resultDatabase = await simpleFetch(
                "/products/search/cip13/" + cip13,
                "GET",
                undefined,
                token
            );

            if (resultMedipim.statusCode === 404) {
                toast.error("Produit introuvable dans Medipim");
                if (resultDatabase.length === 0) {
                    toast.info(
                        "Ce produit n'est pas dans la base de données ni dans la base de donnée Medipim. Aucune donnée préremplie."
                    );
                } else {
                    setDataBasevalues(resultDatabase[0]);

                    toast.info(
                        `Un produit avec le même CIP13 existe dans la base de donnée. Vous modifiez désormais le produit n°
              ${resultDatabase[0]?.id}`
                    );
                    setProductId(resultDatabase[0]?.id);
                }
            } else {
                setMedipimValues(resultMedipim);

                if (resultDatabase.length === 0) {
                    toast.info(
                        "Ce produit n'a jamais été ajouté dans la base de donnée. Les données Médipim ont été préremplies."
                    );
                    setDataBasevalues(resultMedipim);
                } else {
                    setDataBasevalues(resultDatabase[0]);

                    toast.info(
                        "Le produit est déjà dans la base de donnée et dans la base Medipim. Mode comparaison."
                    );
                    toast.info(
                        "Un produit avec le même CIP13 existe dans la base de donnée. Vous modifiez désormais le produit n°" +
                        resultDatabase[0]?.id
                    );
                    setProductId(resultDatabase[0]?.id);
                    navigate(`${resultDatabase[0]?.id}`);
                }
            }
        } else {
            toast.error("Veuillez entrer un code CIP13");
        }
    }

    function setMedipimValues(values) {
        console.log("MEDIPIM", values);
        setFormMedipimValue({
            ...values,
            category: values?.category?.id,
            brand: values?.brand?.id,
        });
    }

    function setDataBasevalues(values) {
        console.log("DATABASE", values);
        setFormDataBaseValue({
            ...values,
            category: values?.category?.id,
            brand: values?.brand?.id,
        });
    }

    const defaultValue = {
        name: "",
        price: "",
        tva: "",
        description: "",
        composition: "",
        indication: "",
        usage: "",
        contreIndication: "",
        sideEffects: "",
        maxQuantity: "",
        category: null,
        brand: null,
        img: "",
        isVisible: "",
    };

    useEffect(() => {
        setProductId(props?.productId);

        if (open && props?.productId) {
            setFormDataBaseValue(defaultValue);
            setFormMedipimValue();
            if (props?.productId) {
                getProduct(props?.productId).then((product) => {
                    // setFormDataBaseValue(product);
                    console.log("PRODUCT", product);
                    if (product?.cip13) {
                        setCip13(product?.cip13);
                        searchFromCIP13(product?.cip13);
                    }
                });
            }

            const token = getToken();
            simpleFetch("/categories", "GET", null, token).then((res) => {
                setCategoriesList(
                    res?.map((category) => ({
                        label: category.name,
                        value: category.id,
                        type:
                            categoryType[category?.type]?.label ||
                            categoryType["other"].label,
                    }))
                );
            });
        }
    }, [open]);

    const [formDataBaseValue, setFormDataBaseValue] = useState(defaultValue);

    const [formMedipimValue, setFormMedipimValue] = useState();

    const arrowStyle = {
        marginTop: 50,
        color: "gray",
    };

    const editor = useRef(null);

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: "Commencez à écrire...",
        showTooltip: false,
        removeButtons: [
            "fullsize",
            "copyformat",
            "image",
            "video",
            "table",
            "link",
            "brush",
            "about",
            "file",
            "classSpan",
        ],
        language: "fr",
        style: {
            height: 400,
        },
    };
    const configMedipim = {
        readonly: true, // all options from https://xdsoft.net/jodit/doc/,
        placeholder: "Commencez à écrire...",
        showTooltip: false,
        disabled: true,
        removeButtons: [
            "fullsize",
            "copyformat",
            "image",
            "video",
            "table",
            "link",
            "brush",
            "about",
            "file",
            "classSpan",
        ],
        language: "fr",
        style: {
            height: 400,
        },
    };

    const model = Schema.Model({
        name: Schema.Types.StringType().isRequired("Ce champs est réquis"),
        price: Schema.Types.NumberType("Ce champs doit être un nombre").when(
            (schema) => {
                if (schema.isVisible?.value === true) {
                    return Schema.Types.NumberType().isRequired(
                        "Ce champs est réquis, si le produit est visible dans l'app"
                    );
                }
                return Schema.Types.NumberType();
            }
        ),
        tva: Schema.Types.NumberType("Ce champs doit être un nombre").when(
            (schema) => {
                if (schema.isVisible?.value === true) {
                    return Schema.Types.NumberType().isRequired(
                        "Ce champs est réquis, si le produit est visible dans l'app"
                    );
                }
                return Schema.Types.NumberType();
            }
        ),
        maxQuantity: Schema.Types.NumberType()
            .isInteger("Ce champs doit être un nombre entier")
            .min(0, "Une quantité ne peut pas être inferieure à 0"),
        isVisible: Schema.Types.BooleanType("Ce champs doit être un booléen"),
        category: Schema.Types.NumberType().isOneOf(
            categoriesList?.map((category) => category.value),
            "Catégorie Invalide"
        ),
        brand: Schema.Types.NumberType().isOneOf(
            categoriesList?.map((category) => category.value),
            "Marque Invalide"
        ),
    });
    const modelCip13 = Schema.Model({
        cip13: Schema.Types.StringType().isRequired("Ce champs est réquis"),
    });
    const formRef = useRef();
    const formRef2 = useRef();

    function submitForm() {
        console.log("submitForm");
        // formResponse must be formated
        // Category, Brand, Image (if not already set), Remove CreatedAt and UpdatedAt
        const formResponse = {
            id: formDataBaseValue?.id || null,
            cip13: cip13,
            composition: formDataBaseValue?.composition || null,
            contreIndication: formDataBaseValue?.contreIndication || null,
            description: formDataBaseValue?.description || null,
            indication: formDataBaseValue?.indication || null,
            isVisible: formDataBaseValue?.isVisible,
            maxQuantity: formDataBaseValue?.maxQuantity || null,
            name: formDataBaseValue?.name || null,
            price: formDataBaseValue?.price || 0,
            sideEffects: formDataBaseValue?.sideEffects || null,
            stock: formDataBaseValue?.stock || 0,
            tva: formDataBaseValue?.tva || 0,
            usage: formDataBaseValue?.usage || null,
            img:
                typeof formDataBaseValue?.img === "string"
                    ? formDataBaseValue?.img
                    : "",
            brand: formDataBaseValue?.brand || null,
            category: formDataBaseValue?.category
                ? formDataBaseValue?.category
                : formDataBaseValue?.brand,
            discount: parseInt(formDataBaseValue?.discount) || 0,
        };

        console.log("formResponse", formResponse);
        if (!formRef.current.check() && !formRef2.current.check()) {
            console.error("Form Error");
            toast.error(
                "Formulaire invalide. Merci de remplir tous les champs demandés"
            );
            return;
        }
        console.log("Form OK", productId, formDataBaseValue?.id);
        const token = getToken();

        if (formDataBaseValue?.id) {
            //Update product
            console.log("Updating product");
            simpleFetch(
                `/products/${formResponse.id}`,
                "PATCH",
                formResponse,
                token
            ).then((res) => {
                console.log(res);
                if (
                    formDataBaseValue?.img &&
                    typeof formDataBaseValue?.img !== "string" &&
                    formDataBaseValue?.img.length > 0 &&
                    formDataBaseValue?.img[0]?.blobFile
                ) {
                    const formData = new FormData();
                    formData.append(
                        "image",
                        formDataBaseValue?.img[0]?.blobFile,
                        formDataBaseValue?.img[0]?.blobFile?.name
                    );
                    simpleFetchImage(
                        `/products/${formDataBaseValue?.id}/image`,
                        "POST",
                        formData,
                        token
                    )
                        .then((res) => {
                            toast.success("Produit mis à jour avec succès");
                            handleClose();
                        })
                        .catch((err) => {
                            toast.error(
                                "Erreur lors de la mise à jour de l'image du produit"
                            );
                        });
                } else {
                    toast.success("Produit mis à jour avec succès");
                    handleClose();
                }
            });
        } else {
            //Create product
            console.log("creating product");
            simpleFetch(`/products`, "POST", formResponse, token).then((res) => {
                console.log(res);
                if (
                    formDataBaseValue?.img &&
                    typeof formDataBaseValue?.img !== "string" &&
                    formDataBaseValue?.img.length > 0 &&
                    formDataBaseValue?.img[0]?.blobFile
                ) {
                    const formData = new FormData();
                    formData.append(
                        "image",
                        formDataBaseValue?.img[0]?.blobFile,
                        formDataBaseValue?.img[0]?.blobFile?.name
                    );
                    simpleFetchImage(
                        `/products/${res?.id}/image`,
                        "POST",
                        formData,
                        token
                    )
                        .then((res) => {
                            toast.success("Produit créé avec succès");
                            handleClose();
                        })
                        .catch((err) => {
                            toast.error("Erreur lors de la mise de l'image du produit");
                        });
                } else {
                    toast.success("Produit créé avec succès");
                    handleClose();
                }
            });
        }
    }

    function transferAllIncompleteData() {
        setFormDataBaseValue({
            ...formDataBaseValue,
            name: formDataBaseValue?.name || formMedipimValue?.name || null,
            price: formDataBaseValue?.price || formMedipimValue?.price || "",
            tva: formDataBaseValue?.tva || formMedipimValue?.tva || "",
            composition:
                formDataBaseValue?.composition || formMedipimValue?.composition || null,
            contreIndication:
                formDataBaseValue?.contreIndication ||
                formMedipimValue?.contreIndication ||
                null,
            description:
                formDataBaseValue?.description || formMedipimValue?.description || null,
            indication:
                formDataBaseValue?.indication || formMedipimValue?.indication || null,
            sideEffects:
                formDataBaseValue?.sideEffects || formMedipimValue?.sideEffects || null,
            usage: formDataBaseValue?.usage || formMedipimValue?.usage || null,
            img: formDataBaseValue?.img || formMedipimValue?.img || null,

            brand: formDataBaseValue?.brand || formMedipimValue?.brand || null,
            category:
                formDataBaseValue?.category || formMedipimValue?.category || null,
        });
        toast.success(
            "Toutes les données vides ont été remplie avec succès. N'oubliez pas de sauvegarder !"
        );
    }

    const [warningVisible, setWarningVisible] = useState(false);
    useEffect(() => {
        checkCategory();
    }, [formDataBaseValue?.category, formDataBaseValue?.brand]);

    function checkCategory() {
        let cat = categoriesList.find(
            (category) => category.value === formDataBaseValue?.category
        );
        let bra = categoriesList.find(
            (brand) => brand.value === formDataBaseValue?.brand
        );
        console.log(
            "formDataBaseValue",
            formDataBaseValue?.category,
            cat,
            formDataBaseValue?.brand,
            bra
        );

        // Si ni la catégorie ni la catégorie n'est classé alors on affiche un warning

        if (
            (!cat || cat?.type === "Non-classé") &&
            (!bra || bra?.type === "Non-classé")
        ) {
            setWarningVisible(true);
        } else {
            setWarningVisible(false);
        }
    }

    const [modifyCatModal, setModifycatModal] = useState(false);
    const [modifyBrandModal, setModifyBrandModal] = useState(false);

    return (
        <div>
            {modifyCatModal && (
                <EditCategoryModal
                    isOpen={modifyCatModal}
                    setOpen={setModifycatModal}
                    callBackOnModify={checkCategory}
                    categoryId={formDataBaseValue?.category}
                    noCkeck={true}
                />
            )}
            {modifyBrandModal && (
                <EditCategoryModal
                    isOpen={modifyBrandModal}
                    setOpen={setModifyBrandModal}
                    callBackOnModify={checkCategory}
                    categoryId={formDataBaseValue?.brand}
                    noCkeck={true}
                />
            )}
            <Modal open={open} onClose={handleClose} size="lg" autoFocus={false}>
                <div className="modal-form-container">
                    <h5
                        className="title"
                        style={{textAlign: "center", marginBottom: 20}}
                    >
                        Ajout et modification d'un produit
                    </h5>

                    <Form fluid ref={formRef2} model={modelCip13} value={{cip13}}>
                        <Form.Group controlId="cip13">
                            <Form.ControlLabel>CIP13</Form.ControlLabel>
                            <Form.Control
                                name="cip13"
                                value={cip13}
                                onChange={(value) => {
                                    console.log(value);
                                    setCip13(value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <ButtonToolbar>
                                <Button
                                    appearance="ghost"
                                    onClick={() => {
                                        searchFromCIP13(cip13);
                                    }}
                                >
                                    Pré-remplir les informations à partir du CIP13
                                </Button>
                            </ButtonToolbar>
                            {formMedipimValue && (
                                <ButtonToolbar style={{marginTop: 20}}>
                                    <Button
                                        appearance="ghost"
                                        color="green"
                                        onClick={() => {
                                            transferAllIncompleteData();
                                        }}
                                    >
                                        Transferer toutes les données Medipim
                                    </Button>
                                </ButtonToolbar>
                            )}

                            {formMedipimValue && (
                                <Button
                                    style={{marginTop: 20}}
                                    appearance="ghost"
                                    color="red"
                                    onClick={() => {
                                        setFormMedipimValue();
                                    }}
                                >
                                    Fermer le mode comparaison
                                </Button>
                            )}
                        </Form.Group>

                        <div
                            style={{
                                flexWrap: "wrap",
                                display: "flex",
                                justifyContent: "space-evenly",
                            }}
                        >
                            <div style={{width: formMedipimValue ? "40%" : "100%"}}>
                                <Form
                                    checkTrigger={"none"}
                                    formValue={formDataBaseValue}
                                    onChange={(formValue) => {
                                        setFormDataBaseValue(formValue);
                                    }}
                                    model={model}
                                    ref={formRef}
                                >
                                    <h5>Version dans la base</h5>
                                    <Form.Group controlId="Nom">
                                        <Form.ControlLabel>Nom du produit</Form.ControlLabel>
                                        <Form.Control name="name"/>
                                    </Form.Group>
                                    <Form.Group controlId="Prix" inline>
                                        <Form.ControlLabel>Prix TTC</Form.ControlLabel>
                                        <Form.Control name="price"/>
                                        <Form.ControlLabel>Dont TVA (%)</Form.ControlLabel>
                                        <Form.Control name="tva"/>
                                    </Form.Group>
                                    <RadioGroup
                                        name="radioList"
                                        inline
                                        appearance="picker"
                                        value={activeItem}
                                        onChange={(value) => setActiveItem(value)}
                                    >
                                        <Radio value="description">Description</Radio>
                                        <Radio value="composition">Composition</Radio>
                                        <Radio value="indication">Indication</Radio>
                                    </RadioGroup>
                                    <RadioGroup
                                        name="radioList"
                                        inline
                                        appearance="picker"
                                        value={activeItem}
                                        onChange={(value) => setActiveItem(value)}
                                    >
                                        <Radio value="usage">Usages</Radio>
                                        <Radio value="contreIndication">Contre indications</Radio>
                                        <Radio value="sideEffects">Effets secondaires</Radio>
                                    </RadioGroup>
                                    <Form.Group>
                                        <JoditEditor
                                            ref={editor}
                                            value={formDataBaseValue[activeItem] || ""}
                                            config={config}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={(newContent) => {
                                                let newFormValue = formDataBaseValue;
                                                newFormValue[activeItem] = newContent;
                                                setFormDataBaseValue({
                                                    ...newFormValue,
                                                });
                                            }} // preferred to use only this option to update the content for performance reasons
                                            onChange={(newContent) => {
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        controlId="Quantité maximale par commande"
                                        style={{width: "100%"}}
                                    >
                                        <Form.ControlLabel>
                                            Quantité maximale par commande
                                        </Form.ControlLabel>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Form.Control
                                                name="maxQuantity"
                                                accepter={InputNumber}
                                                min={0}
                                            />
                                            {formDataBaseValue?.maxQuantity && (
                                                <Button
                                                    onClick={() => {
                                                        setFormDataBaseValue({
                                                            ...formDataBaseValue,
                                                            maxQuantity: null,
                                                        });
                                                    }}
                                                >
                                                    X
                                                </Button>
                                            )}
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="Catégorie">
                                        <Form.ControlLabel>
                                            Catégorie{"  "}
                                            <Whisper
                                                placement="top"
                                                speaker={
                                                    <Tooltip>
                                                        Si la catégorie n'est pas classée dans un Type elle
                                                        ne sera d'aucune utilité.
                                                    </Tooltip>
                                                }
                                            >
                                                <InfoRoundIcon/>
                                            </Whisper>
                                        </Form.ControlLabel>
                                        <Form.Control
                                            placeholder="Séléctionner"
                                            name="category"
                                            locale={{
                                                searchPlaceholder: "Rechercher",
                                            }}
                                            accepter={SelectPicker}
                                            data={categoriesList}
                                            groupBy={"type"}
                                            block
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="Marque">
                                        <Form.ControlLabel>
                                            Marque{"  "}
                                            <Whisper
                                                placement="top"
                                                speaker={
                                                    <Tooltip>
                                                        Si la marque n'est pas classée dans un Type elle ne
                                                        sera d'aucune utilité.
                                                    </Tooltip>
                                                }
                                            >
                                                <InfoRoundIcon/>
                                            </Whisper>
                                        </Form.ControlLabel>
                                        <Form.Control
                                            placeholder="Séléctionner"
                                            name="brand"
                                            locale={{
                                                searchPlaceholder: "Rechercher",
                                            }}
                                            accepter={SelectPicker}
                                            data={categoriesList}
                                            groupBy={"type"}
                                            block
                                        />
                                    </Form.Group>
                                    {/* <Form.Group controlId="Marque">
                    <Form.ControlLabel>Marque</Form.ControlLabel>
                    <Form.Control name="brand" />
                  </Form.Group> */}
                                    <Form.Group controlId="Imagne">
                                        <Form.ControlLabel>Image</Form.ControlLabel>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Uploader
                                                removable={false}
                                                autoUpload={false}
                                                listType="picture"
                                                accept={["image/png", "image/jpeg"]}
                                                onChange={(img) => {
                                                    console.log("IMG LIST", img);
                                                    setFormDataBaseValue({
                                                        ...formDataBaseValue,
                                                        img: img.length >= 1 ? [img[img.length - 1]] : [],
                                                    });
                                                }}
                                                fileList={
                                                    formDataBaseValue?.img
                                                        ? [
                                                            typeof formDataBaseValue?.img === "string"
                                                                ? {
                                                                    url: formDataBaseValue?.img,
                                                                    name: "a.png",
                                                                    fileKey: 1,
                                                                }
                                                                : formDataBaseValue?.img[0],
                                                        ]
                                                        : []
                                                }
                                            >
                                                <button type={"button"}>
                                                    <FaCameraRetro/>
                                                </button>
                                            </Uploader>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="Prix" inline>
                                        <Form.ControlLabel>Promotion (en %)</Form.ControlLabel>
                                        <Form.Control name="discount" accepter={InputNumber}/>
                                        <Form.ControlLabel>
                                            Prix TTC avec réduction calculé :{" "}
                                            {formDataBaseValue?.price &&
                                            formDataBaseValue?.tva &&
                                            formDataBaseValue?.discount
                                                ? (
                                                    formDataBaseValue?.price *
                                                    (1 - formDataBaseValue?.discount / 100)
                                                ).toFixed(2) >= 0
                                                    ? (
                                                        formDataBaseValue?.price *
                                                        (1 - formDataBaseValue?.discount / 100)
                                                    ).toFixed(2)
                                                    : "Prix invalide"
                                                : "-"}
                                        </Form.ControlLabel>
                                    </Form.Group>
                                    <Form.Group controlId="Prix" inline>
                                        <Form.ControlLabel>Stock</Form.ControlLabel>
                                        <Form.Control name="stock" accepter={InputNumber}/>
                                    </Form.Group>
                                    Visibilité dans l'app:
                                    <Checkbox
                                        checked={formDataBaseValue?.isVisible}
                                        onChange={(value) =>
                                            setFormDataBaseValue({
                                                ...formDataBaseValue,
                                                isVisible: !formDataBaseValue?.isVisible,
                                            })
                                        }
                                    />{" "}
                                    Est visible
                                </Form>
                            </div>
                            {formMedipimValue && (
                                <div
                                    style={{
                                        heigth: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "5%",
                                        // justifyContent: "space-evenly",
                                    }}
                                >
                                    <TiArrowLeftThick
                                        onMouseOver={({target}) => (target.style.color = "black")}
                                        onMouseOut={({target}) => (target.style.color = "gray")}
                                        size={32}
                                        style={{...arrowStyle, marginTop: 50}}
                                        onClick={() => {
                                            setFormDataBaseValue({
                                                ...formDataBaseValue,
                                                name: formMedipimValue?.name || null,
                                            });
                                        }}
                                    />
                                    <div
                                        style={{
                                            heigth: "1000px",
                                            display: "flex",
                                            flex: 0.08,
                                            flexDirection: "column",
                                            // backgroundColor: "red",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <TiArrowLeftThick
                                            onMouseOver={({target}) =>
                                                (target.style.color = "black")
                                            }
                                            onMouseOut={({target}) => (target.style.color = "gray")}
                                            size={32}
                                            style={{...arrowStyle, marginTop: 50}}
                                            onClick={() => {
                                                setFormDataBaseValue({
                                                    ...formDataBaseValue,
                                                    price: formMedipimValue?.price || "",
                                                });
                                            }}
                                        />
                                        <TiArrowLeftThick
                                            onMouseOver={({target}) =>
                                                (target.style.color = "black")
                                            }
                                            onMouseOut={({target}) => (target.style.color = "gray")}
                                            size={32}
                                            style={{...arrowStyle, marginTop: 30}}
                                            onClick={() => {
                                                setFormDataBaseValue({
                                                    ...formDataBaseValue,
                                                    tva: formMedipimValue?.tva || "",
                                                });
                                            }}
                                        />
                                    </div>
                                    <TiArrowLeftThick
                                        onMouseOver={({target}) => (target.style.color = "black")}
                                        onMouseOut={({target}) => (target.style.color = "gray")}
                                        size={32}
                                        style={{...arrowStyle, marginTop: 375}}
                                        onClick={() => {
                                            let temp = formDataBaseValue;
                                            temp[activeItem] = formMedipimValue?.[activeItem];
                                            setFormDataBaseValue({
                                                ...temp,
                                            });
                                        }}
                                    />

                                    <TiArrowLeftThick
                                        onMouseOver={({target}) => (target.style.color = "black")}
                                        onMouseOut={({target}) => (target.style.color = "gray")}
                                        hover={true}
                                        size={32}
                                        style={{...arrowStyle, marginTop: 265}}
                                        onClick={() => {
                                            setFormDataBaseValue({
                                                ...formDataBaseValue,
                                                maxQuantity: formMedipimValue?.maxQuantity || null,
                                            });
                                        }}
                                    />
                                    <TiArrowLeftThick
                                        onMouseOver={({target}) => (target.style.color = "black")}
                                        onMouseOut={({target}) => (target.style.color = "gray")}
                                        size={32}
                                        style={arrowStyle}
                                        onClick={() => {
                                            setFormDataBaseValue({
                                                ...formDataBaseValue,
                                                category: formMedipimValue?.category || null,
                                            });
                                        }}
                                    />
                                    <TiArrowLeftThick
                                        onMouseOver={({target}) => (target.style.color = "black")}
                                        onMouseOut={({target}) => (target.style.color = "gray")}
                                        size={32}
                                        style={arrowStyle}
                                        onClick={() => {
                                            setFormDataBaseValue({
                                                ...formDataBaseValue,
                                                brand: formMedipimValue?.brand || null,
                                            });
                                        }}
                                    />
                                    <TiArrowLeftThick
                                        onMouseOver={({target}) => (target.style.color = "black")}
                                        onMouseOut={({target}) => (target.style.color = "gray")}
                                        size={32}
                                        style={{...arrowStyle, marginTop: 75}}
                                        onClick={() => {
                                            setFormDataBaseValue({
                                                ...formDataBaseValue,
                                                img: formMedipimValue?.img || null,
                                            });
                                        }}
                                    />
                                    {/* <TiArrowLeftThick     onMouseOver={({ target }) => (target.style.color = "black")}
                    onMouseOut={({ target }) => (target.style.color = "gray")} size={32} style={arrowStyle} /> */}
                                </div>
                            )}
                            {formMedipimValue && (
                                <div style={{width: "40%"}}>
                                    <Form
                                        disabled
                                        formValue={formMedipimValue}
                                        onChange={(formValue) => setFormMedipimValue(formValue)}
                                    >
                                        <h5>Version Médipim</h5>
                                        <Form.Group controlId="Nom">
                                            <Form.ControlLabel>Nom du produit</Form.ControlLabel>
                                            <Form.Control name="name"/>
                                        </Form.Group>
                                        <Form.Group controlId="Prix" inline>
                                            <Form.ControlLabel>Prix TTC</Form.ControlLabel>
                                            <Form.Control name="price"/>
                                            <Form.ControlLabel>Dont TVA (%)</Form.ControlLabel>
                                            <Form.Control name="tva"/>
                                        </Form.Group>
                                        <RadioGroup
                                            name="radioList"
                                            inline
                                            appearance="picker"
                                            value={activeItem}
                                            onChange={(value) => setActiveItem(value)}
                                        >
                                            <Radio value="description">Description</Radio>
                                            <Radio value="composition">Composition</Radio>
                                            <Radio value="indication">Indication</Radio>
                                        </RadioGroup>
                                        <RadioGroup
                                            name="radioList"
                                            inline
                                            appearance="picker"
                                            value={activeItem}
                                            onChange={(value) => setActiveItem(value)}
                                        >
                                            <Radio value="usage">Usages</Radio>
                                            <Radio value="contreIndication">Contre indications</Radio>
                                            <Radio value="sideEffects">Effets secondaires</Radio>
                                        </RadioGroup>
                                        <Form.Group>
                                            <JoditEditor
                                                ref={editor}
                                                value={formMedipimValue[activeItem] || ""}
                                                config={configMedipim}
                                                tabIndex={1} // tabIndex of textarea
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Quantité maximale par commande">
                                            <Form.ControlLabel>
                                                Quantité maximale par commande
                                            </Form.ControlLabel>

                                            <Form.Control
                                                name="maxQuantity"
                                                accepter={InputNumber}
                                                min={0}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Catégorie">
                                            <Form.ControlLabel>Catégorie</Form.ControlLabel>
                                            <Form.Control
                                                placeholder="Séléctionner"
                                                name="category"
                                                locale={{
                                                    searchPlaceholder: "Rechercher",
                                                }}
                                                accepter={SelectPicker}
                                                data={categoriesList}
                                                groupBy={"type"}
                                                block
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Marque">
                                            <Form.ControlLabel>Marque</Form.ControlLabel>
                                            <Form.Control
                                                placeholder="Séléctionner"
                                                name="brand"
                                                locale={{
                                                    searchPlaceholder: "Rechercher",
                                                }}
                                                accepter={SelectPicker}
                                                data={categoriesList}
                                                groupBy={"type"}
                                                block
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="Imagne">
                                            <Form.ControlLabel>Image</Form.ControlLabel>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <Uploader
                                                    removable={false}
                                                    disabled
                                                    autoUpload={false}
                                                    listType="picture"
                                                    accept={["image/png", "image/jpeg"]}
                                                    onChange={(img) => {
                                                        console.log("IMG LIST", img);
                                                        setFormMedipimValue({
                                                            ...formMedipimValue,
                                                            img: img.length >= 1 ? [img[img.length - 1]] : [],
                                                        });
                                                    }}
                                                    fileList={
                                                        formMedipimValue?.img
                                                            ? [
                                                                typeof formMedipimValue?.img === "string"
                                                                    ? {
                                                                        url: formMedipimValue?.img,
                                                                        name: "a.png",
                                                                        fileKey: 1,
                                                                    }
                                                                    : formMedipimValue?.img[0],
                                                            ]
                                                            : []
                                                    }
                                                >
                                                    <button type={"button"}>
                                                        <FaCameraRetro/>
                                                    </button>
                                                </Uploader>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
                {warningVisible && (
                    <Message
                        style={{marginBottom: 10}}
                        showIcon
                        type="warning"
                        header="Catégorie non visible dans l'application"
                    >
                        La catégorie et la marque indiquée dans le champs "Catégorie" et
                        "Marque" ne sont pas associé à un type. Changez de catégorie/marque
                        ou associez cette catégorie/marque à un type.
                        <br/>
                        Vous pouvez laisser comme ça mais le produit n'apparaitra pas dans
                        l'application. (Sauf en mode Recherche)
                        <br/>
                        {formDataBaseValue?.cat && (
                            <Button
                                appearance="ghost"
                                style={{marginTop: 10}}
                                onClick={() => setModifycatModal(true)}
                            >
                                Modifier le type de la catégorie séléctionnée
                            </Button>
                        )}
                        {formDataBaseValue?.brand && (
                            <Button
                                appearance="ghost"
                                style={{marginTop: 10}}
                                onClick={() => setModifyBrandModal(true)}
                            >
                                Modifier le type de la marque séléctionnée
                            </Button>
                        )}
                    </Message>
                )}
                <Form.Group>
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={submitForm}>
                            Ajouter / Modifier le produit
                        </Button>
                        <Button appearance="default" onClick={handleClose}>
                            Fermer (Annule les modifications non sauvegardées)
                        </Button>
                    </ButtonToolbar>
                </Form.Group>
            </Modal>
        </div>
    );
}

export default AddProductModal;
