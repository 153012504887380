import React, { useState, useEffect } from "react";
import {
  Checkbox,
  CheckboxGroup,
  Modal,
  InputNumber,
  Input,
  Toggle,
  SelectPicker,
  FlexboxGrid,
  Button,
  Message,
} from "rsuite";
import { useAuthentification } from "../Context/AuthContext";
import simpleFetch from "../data/simpleFetch";
import categoryType from "../data/categoriesType";
import { toast } from "react-toastify";

const ControlRow = ({ label, control, ...rest }) => (
  <FlexboxGrid
    {...rest}
    style={{ marginBottom: 10 }}
    align="middle"
    justify="space-between"
  >
    <FlexboxGrid.Item colspan={7}>{label} </FlexboxGrid.Item>
    <FlexboxGrid.Item colspan={15}>{control}</FlexboxGrid.Item>
  </FlexboxGrid>
);
function QuickEditModal({ toggle, isVisible, dataToEdit }) {
  console.log(dataToEdit);
  const [categoriesList, setCategoriesList] = useState([]);

  const properties = [
    "price",
    "tva",
    "maxQuantity",
    "category",
    "brand",
    "discount",
    "isVisible",
  ];

  const prop = {
    price: { label: "Prix", type: "number" },
    tva: { label: "Dont TVA (%)", type: "number" },
    maxQuantity: { label: "Quantité maximale par commande", type: "int" },
    category: { label: "Catégorie", type: "cat" },
    brand: { label: "Marque", type: "cat" },
    discount: { label: "Promotion", type: "number" },
    isVisible: { label: "Visibilité dans l'app", type: "bool" },
  };

  const [checkedProperties, setCheckedProperties] = React.useState([]);
  const { getToken } = useAuthentification();

  useEffect(() => {
    const token = getToken();
    simpleFetch("/categories", "GET", null, token).then((res) => {
      setCategoriesList(
        res?.map((category) => ({
          label: category.name,
          value: category.id,
          type:
            categoryType[category?.type]?.label || categoryType["other"].label,
        }))
      );
    });
  }, []);
  const [values, setValues] = useState({});
  function editAll() {
    let dataToSend = {};
    console.log("edit all");
    console.log("toEdit", dataToEdit);
    checkedProperties.forEach((property) => {
      if (!!values[property]) {
        if (prop[property].type === "number") {
          dataToSend[property] = parseFloat(values[property]);
        } else {
          dataToSend[property] = values[property];
        }
      } else {
        if (prop[property].type === "cat") {
          dataToSend[property] = null;
        } else if (prop[property].type === "bool") {
          dataToSend[property] = false;
        } else if (prop[property].type === "int") {
          dataToSend[property] = 0;
        } else if (prop[property].type === "number") {
          dataToSend[property] = 0;
        }
      }
    });
    console.log("to send", dataToSend);
    console.log("edit all end");
    dataToEdit.forEach((product) => {
      const token = getToken();
      simpleFetch(`/products/${product}`, "PATCH", dataToSend, token).then(
        (res) => {}
      );
      toast.success(
        "Les produits ont été modifiés, vous pouvez actualiser la page pour voir les modifications dans la liste."
      );
    });
  }
  return (
    <Modal open={isVisible} onClose={toggle}>
      <Modal.Title>
        <h1 style={{ fontSize: 18, margin: 0, padding: 0, lineHeight: 1 }}>
          Edition de {dataToEdit?.length} produits
        </h1>
      </Modal.Title>
      <Modal.Body>
        <h2 style={{ fontSize: 15 }}>
          Selectionnez les propriétés que vous souhaitez modifier
        </h2>
        <CheckboxGroup
          value={checkedProperties}
          name="properties"
          onChange={(value) => {
            console.log("hola", value);
            setCheckedProperties(value);
          }}
        >
          {properties.map((property) => (
            <Checkbox key={property} value={property}>
              {prop[property].label}
            </Checkbox>
          ))}
        </CheckboxGroup>
        <h2 style={{ fontSize: 15 }}>Modification des propriétés</h2>
        {checkedProperties.map((property) => {
          console.log("hey", property);
          if (prop[property].type === "int") {
            return (
              <ControlRow
                label={prop[property].label}
                control={
                  <InputNumber
                    onChange={(value) => {
                      setValues({ ...values, [property]: parseInt(value) });
                    }}
                    value={values[property]}
                  />
                }
              />
            );
          }
          if (prop[property].type === "number") {
            return (
              <ControlRow
                label={prop[property].label}
                control={
                  <InputNumber
                    onChange={(value) => {
                      console.log("val", value);
                      setValues({
                        ...values,
                        [property]: value,
                      });
                    }}
                    value={values[property]}
                  />
                }
              />
            );
          }
          if (prop[property].type === "bool") {
            return (
              <ControlRow
                label={prop[property].label}
                control={
                  <Toggle
                    onChange={(value) => {
                      setValues({ ...values, [property]: value });
                    }}
                    value={values[property]}
                  />
                }
              />
            );
          }
          if (prop[property].type === "cat") {
            return (
              <ControlRow
                label={prop[property].label}
                control={
                  <SelectPicker
                    onChange={(value) => {
                      setValues({ ...values, [property]: value });
                    }}
                    value={values[property]}
                    placeholder="Séléctionner"
                    data={categoriesList}
                    groupBy={"type"}
                    name="category"
                    locale={{
                      searchPlaceholder: "Rechercher",
                    }}
                  />
                }
              />
            );
          }
          return <div>{prop[property].label}</div>;
        })}
        <Message
          style={{ marginBottom: 10 }}
          showIcon
          type="warning"
          header="Action non réversible"
        >
          Les changements fait sur les produits ne peuvent pas être annulés.
          <br />
          Toutes les propriétés cochés seront modifiés pour tous les produits.
          Cocher une propriété sans donner de valeur supprimera les valeurs
          actuelles (Ou remettra les valeurs par défaut, ex: tva/prix à 0,
          aucune catégorie/marque, ect.).
          <br />
        </Message>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Button appearance="primary" onClick={editAll}>
            Modifier les {dataToEdit?.length} produits{" "}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default QuickEditModal;
