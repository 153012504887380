import React, {useState} from "react";
import {useAuthentification} from "../../Context/AuthContext";
import useFetch from "../../data/useFetch";
import {toast} from "react-toastify";
import ConfirmationModal from "../../Components/ConfirmationModal";
import QuickEditModal from "../../Components/QuickEditModal";
import {
    Button,
    Checkbox,
    CheckboxGroup,
    Input,
    Table,
    Uploader,
    RadioGroup,
    Radio,
    InputGroup,
    Toggle,
} from "rsuite";
import {useLocation} from "react-router-dom";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import {FaCameraRetro} from "react-icons/fa";
import FormGroup from "rsuite/esm/FormGroup";
import {useNavigate, useParams} from "react-router-dom";
import categoriesTypes from "../../data/categoriesType.json";
import simpleFetch from "../../data/simpleFetch";
import AddProductModal from "./AddProductModal";
import {useCalling} from "../../Context/CallContext";
import {useEffect} from "react";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import "./products.css";
import {handleSortColumn} from "../../utils/handleSortColumn";

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}) => (
    <Table.Cell {...props} style={{padding: 0}}>
        <div style={{lineHeight: "46px"}}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                checked={checkedKeys.some((item) => item === rowData[dataKey])}
            />
        </div>
    </Table.Cell>
);

const ProductList = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [sortLoading, setSortLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(params?.productId);
    const {getToken} = useAuthentification();

    const categoriesList = Object.keys(categoriesTypes);
    const [categoriesFilter, setCategoriesFiler] = useState(categoriesList);

    const handleCheckAllStatus = (value, checked) =>
        setCategoriesFiler(checked ? [...categoriesList] : []);
    const handleChangeSortStatus = (value) => setCategoriesFiler(value);

    const token = getToken();
    const {
        data,

        loading,
        requestData: reloadProducts,
    } = useFetch("/products", "GET", token);

    const getData = () => {
        let newData = data;
        if (newData) {
            newData = newData?.map((item) => {
                if (
                    (item.discount > 0 || !hasPromotion) &&
                    (item?.isVisible || !isVisibleInApp)
                ) {
                    return {
                        categoryId: item?.category?.id || "-",
                        categoryName: item?.category?.name || "-",
                        categoryType: item?.category?.type || "-",
                        categoryMedipimId: item?.category?.medipimId || "-",
                        brandId: item?.brand?.id || "-",
                        brandName: item?.brand?.name || "-",
                        brandType: item?.brand?.type || "-",
                        brandMedipimId: item?.brand?.medipimId || "-",
                        ...item,
                    };
                }
                return {};
            });
        }

        if (sortColumn && sortType) {
            newData.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (sortColumn !== "lastModifiedAt" && sortColumn !== "createdAt") {
                    if (typeof x === "string") {
                        x = x.charCodeAt();
                    }
                    if (typeof y === "string") {
                        y = y.charCodeAt();
                    }
                    if (sortType === "asc") {
                        return x - y;
                    } else {
                        return y - x;
                    }
                } else {
                    if (sortType === "asc") {
                        return new Date(x) - new Date(y);
                    } else {
                        return new Date(y) - new Date(x);
                    }
                }
            });
        }
        if (newData) {
            newData = newData?.map((item) =>
                categoriesFilter.includes(item?.categoryType) ||
                categoriesFilter.includes(item?.brandType)
                    ? item
                    : categoriesFilter.includes("other") &&
                    item?.categoryType === "-" &&
                    item?.brandType === "-"
                        ? item
                        : null
            );
            newData = newData.filter((item) => (item?.id ? item : null));

            if (search) {
                newData = newData
                    .filter((item) => (item?.id ? item : null))
                    .filter((item) =>
                        (
                            item?.cip13 +
                            item?.name +
                            item?.categoryName +
                            item?.categoryType +
                            item?.brandName +
                            item?.brandType
                        )
                            ?.toString()
                            .toLowerCase()
                            .includes(search.toLowerCase())
                            ? item
                            : null
                    );
            }
            return newData;
        }

        return [];
    };

    const [createCategoryModal, setCreateCategoryModal] = useState(
        params?.productId ? true : false
    );
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryType, setNewCategoryType] = useState("pharmacy");
    const [newCategoryImage, setNewCategoryImage] = useState([]);
    const [newCatLoading, setNewCatLoading] = useState(false);
    const [hasPromotion, setHasPromotion] = useState(false);
    const [isVisibleInApp, setIsVisibleInApp] = useState(false);
    const [proMode, setProMode] = useState(false);
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    let location = useLocation();

    const [cartMode, setCartMode] = useState(false);
    useEffect(() => {
        setCartMode(location?.pathname.includes("cart"));
    }, [location]);

    const [checkedKeys, setCheckedKeys] = useState([]);
    let checked = false;
    let indeterminate = false;

    if (checkedKeys?.length === data?.length) {
        checked = true;
    } else if (checkedKeys.length === 0) {
        checked = false;
    } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
    }

    const handleCheckAll = (value, checked) => {
        const keys = checked ? getData().map((item) => item.id) : [];
        setCheckedKeys(keys);
        toast.success(`${keys?.length} produits sélectionnés !`);
    };
    const handleCheck = (value, checked) => {
        const keys = checked
            ? [...checkedKeys, value]
            : checkedKeys.filter((item) => item !== value);
        setCheckedKeys(keys);
    };

    const [quickEditModal, setQuickEditModal] = useState(false);

    function toggleQuickEditModal() {
        setQuickEditModal(!quickEditModal);
    }

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            {quickEditModal ? (
                <QuickEditModal
                    isVisible={quickEditModal}
                    toggle={toggleQuickEditModal}
                    dataToEdit={checkedKeys}
                />
            ) : null}
            <div>
                <h1 className="title">
                    Liste des produits {cartMode ? "- Ajout au panier" : null}
                </h1>
                <div className="top-section">
                    <div>
                        <label>Edition Rapide </label>
                        <Toggle
                            checked={proMode}
                            onChange={(value) => setProMode(value)}
                            arial-label="Switch"
                            checkedChildren="On"
                            unCheckedChildren="Off"
                        />
                    </div>
                    <div className="subtitle-wrapper-filter">
                        <h5 className="subtitle">Filtres</h5>
                        <button onClick={() => setFiltersExpanded(!filtersExpanded)}
                                className={`filters-expander ${filtersExpanded ? "expanded" : ""}`}>
                            <img src={require("../../assets/icons/expand.svg").default} alt="ouvrir les filtres"/>
                        </button>
                    </div>
                    <div className={`filter-wrapper ${filtersExpanded ? "" : "reduced"}`}>
                        <Checkbox
                            indeterminate={
                                categoriesFilter.length > 0 &&
                                categoriesFilter.length < categoriesList.length
                            }
                            checked={categoriesFilter.length === categoriesList.length}
                            onChange={handleCheckAllStatus}
                        >
                            Séléctionner tout les types de catégories
                        </Checkbox>
                        <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={categoriesFilter}
                            onChange={handleChangeSortStatus}
                            className="checkboxGroup-custom"
                        >
                            {categoriesList?.map((item) => (
                                <Checkbox key={item} value={item}>
                                    {categoriesTypes[item].label}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                        <Checkbox
                            checked={hasPromotion}
                            onChange={() => setHasPromotion(!hasPromotion)}
                        >
                            Produits avec promotion uniquement
                        </Checkbox>
                        <Checkbox
                            checked={isVisibleInApp}
                            onChange={() => setIsVisibleInApp(!isVisibleInApp)}
                        >
                            Produits visible dans l'app uniquement
                        </Checkbox>


                        <div>
                            <h6 className="filter-title">Recherche :</h6>
                            <FormGroup inline className="search-wrapper">
                                <Input
                                    placeholder="Nom du produit, code cip13, nom de la catégorie, type de catégorie, nom de la marque, type de marque"
                                    style={{width: "100%", maxWidth: 600, marginRight: 10}}
                                    onChange={(value) => setSearch(value)}
                                    value={search}
                                />

                                {search && (
                                    <Button
                                        appearance="ghost"
                                        color="red"
                                        onClick={() => setSearch("")}
                                    >
                                        Supprimer
                                    </Button>
                                )}
                            </FormGroup>
                        </div>
                    </div>
                    {!cartMode && (
                        <Button
                            style={{marginBottom: 20}}
                            appearance={"primary"}
                            className="btn-main"
                            onClick={() => {
                                setNewCatLoading(false);
                                setNewCategoryImage([]);
                                setNewCategoryName("");
                                setNewCategoryType("pharmacy");
                                setSelectedProduct(undefined);
                                setCreateCategoryModal(true);
                            }}
                        >
                            Ajouter un produit
                        </Button>
                    )}
                </div>
                {!!proMode ? (
                    <div>
                        Mode édition rapide - {checkedKeys?.length} produits séléctionés
                    </div>
                ) : null}
                <AddProductModal
                    open={createCategoryModal}
                    setOpen={setCreateCategoryModal}
                    productId={selectedProduct}
                />
                <ConfirmationModal
                    title={"Ajout d'un produit"}
                    // open={createCategoryModal}
                    setOpen={setCreateCategoryModal}
                    loading={newCatLoading}
                    acceptText={"Créer"}
                    acceptAction={() => {
                        setNewCatLoading(true);
                        if (!newCategoryName || !newCategoryType) {
                            toast.error("Veuillez remplir tous les champs", {
                                autoClose: 2000,
                            });
                            setNewCatLoading(false);
                            return;
                        }
                        const token = getToken();

                        simpleFetch(
                            "/categories",
                            "POST",
                            {
                                name: newCategoryName,
                                type: newCategoryType,
                            },
                            token
                        ).then((res) => {
                            console.log(res);
                            reloadProducts(() => {
                                setCreateCategoryModal(false);
                                toast.success("Produit créée avec succès");
                            });
                        });
                    }}
                >
                    <FormGroup style={{flexDirection: "column"}}>
                        <div>
                            <h5>Nom de catégorie :</h5>

                            <Input
                                placeholder="Nom de la catégorie"
                                style={{maxWidth: 400}}
                                onChange={(value) => setNewCategoryName(value)}
                                value={newCategoryName}
                            />
                        </div>
                        <div style={{marginTop: 20}}>
                            <h5>Image de catégorie :</h5>
                            <Uploader
                                fileList={newCategoryImage}
                                autoUpload={false}
                                listType="picture"
                                onChange={(img) => {
                                    setNewCategoryImage(
                                        img.length >= 1 ? [img[img.length - 1]] : []
                                    );
                                }}
                                value={newCategoryImage}
                                accept={["image/png", "image/jpeg"]}
                            >
                                <button>
                                    <FaCameraRetro/>
                                </button>
                            </Uploader>
                        </div>
                        <div>
                            <RadioGroup
                                inline
                                name="radioList"
                                value={newCategoryType}
                                onChange={(value) => {
                                    setNewCategoryType(value);
                                }}
                            >
                                <Radio value="pharmacy">Pharmacie</Radio>
                                <Radio value="cosmetic">Cosmétique et beauté</Radio>
                                <Radio value="food">Compléments alimentaires</Radio>
                            </RadioGroup>
                        </div>
                    </FormGroup>
                </ConfirmationModal>
            </div>
            <div style={{flex: 1}}>
                <Table
                    virtualized
                    fillHeight
                    cellHeight={60}
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={(sortColumn, sortType) => handleSortColumn(sortColumn, sortType, setSortLoading, setSortColumn, setSortType)}
                    loading={loading || sortLoading}
                    onRowClick={(data) => {
                        if (!cartMode && !proMode) {
                            console.log("DATA", data);
                            //   navigate(data.id.toString());
                            navigate(`${data.id}`);
                            setSelectedProduct(data.id);
                            setCreateCategoryModal(true);
                        } else {
                        }
                    }}
                    renderEmpty={() => (
                        <div>Aucun produit ne correspond aux critères demandé.</div>
                    )}
                >
                    {!!proMode && (
                        <Table.Column align="center" width={40}>
                            <Table.HeaderCell style={{padding: 0}}>
                                <div style={{lineHeight: "46px"}}>
                                    <Checkbox
                                        inline
                                        checked={checked}
                                        indeterminate={indeterminate}
                                        onChange={handleCheckAll}
                                    />
                                </div>
                            </Table.HeaderCell>
                            <CheckCell
                                dataKey="id"
                                checkedKeys={checkedKeys}
                                onChange={handleCheck}
                            />
                        </Table.Column>
                    )}
                    {!!cartMode && (
                        <Table.Column align="center" width={40}>
                            <Table.HeaderCell></Table.HeaderCell>
                            <AddToCartCell dataKey="id"/>
                        </Table.Column>
                    )}

                    <Table.Column align="center" sortable width={140}>
                        <Table.HeaderCell>cip13</Table.HeaderCell>
                        <Table.Cell dataKey="cip13"/>
                    </Table.Column>
                    <Table.Column align="center" sortable flexGrow={3}>
                        <Table.HeaderCell>Nom</Table.HeaderCell>
                        <Table.Cell dataKey="name"/>
                    </Table.Column>
                    <Table.Column align="center" width={50}>
                        <Table.HeaderCell>Image</Table.HeaderCell>
                        <HasContentCell dataKey="img"/>
                    </Table.Column>

                    <Table.Column align="center" sortable flexGrow={1}>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <TypeCell dataKey="categoryType"/>
                    </Table.Column>
                    <Table.Column align="center" sortable flexGrow={1}>
                        <Table.HeaderCell>Categorie</Table.HeaderCell>
                        <Table.Cell dataKey="categoryName"/>
                    </Table.Column>
                    <Table.Column align="center" sortable flexGrow={1}>
                        <Table.HeaderCell>Marque</Table.HeaderCell>
                        <Table.Cell dataKey="brandName"/>
                    </Table.Column>

                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Stock</Table.HeaderCell>
                        <Table.Cell dataKey="stock"/>
                    </Table.Column>

                    <Table.Column align="center" sortable width={50}>
                        <Table.HeaderCell>Quantité max</Table.HeaderCell>
                        <Table.Cell dataKey="maxQuantity"/>
                    </Table.Column>
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Prix</Table.HeaderCell>
                        <Table.Cell dataKey="price"/>
                    </Table.Column>
                    <Table.Column align="center" sortable width={50}>
                        <Table.HeaderCell>TVA (%)</Table.HeaderCell>
                        <Table.Cell dataKey="tva"/>
                    </Table.Column>
                    <Table.Column align="center" sortable width={50}>
                        <Table.HeaderCell>Promotion (%)</Table.HeaderCell>
                        <HasContentCell dataKey="discount"/>
                    </Table.Column>

                    <Table.Column align="center" width={100}>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <DeleteCell
                            reloadProducts={(callback) => reloadProducts(callback)}
                            dataKey="id"
                            setSelectedProduct={setSelectedProduct}
                            setModificationOpen={setCreateCategoryModal}
                        />
                    </Table.Column>
                </Table>
            </div>
            {proMode && (
                <>
                    <div style={{marginTop: 20}}>
                        <Button appearance={"primary"} onClick={toggleQuickEditModal}>
                            Modifier les produits séléctionnés
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

const AddToCartCell = ({rowData, dataKey, ...props}) => {
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const {addToCart} = useCalling();

    const [value, setValue] = useState(rowData?.stock > 0 ? 1 : 0);
    const handleMinus = () => {
        if (value > 1) {
            setValue(parseInt(value, 10) - 1);
        } else {
            toast.error("Vous ne pouvez pas insérer une quantité négative");
        }
    };
    const handlePlus = () => {
        if (value < rowData?.stock) {
            setValue(parseInt(value, 10) + 1);
        } else {
            toast.error(
                "Vous ne pouvez pas insérer une quantité supérieure au stock. Le client se retrouverait avec un produit en rupture de stock et ne pourrait pas finaliser sa commande."
            );
        }
    };

    return (
        <Table.Cell
            {...props}
            style={{
                paddingTop: 0,
                padding: 0,
                justifyContent: "space-evenly",
                alignItems: "center",
                display: "flex",
            }}
        >
            <ConfirmationModal
                open={modalAddOpen}
                setOpen={setModalAddOpen}
                title={
                    "Ajout du produit n°" + rowData[dataKey] + " au panier du client"
                }
                acceptText={"Ajouter au panier"}
                acceptAction={() => addToCart(rowData[dataKey], value)}
            >
                <div style={{marginBottom: 10}}>
                    <h6>Produit:</h6> {rowData?.name}
                </div>
                <div style={{marginBottom: 10}}>
                    <h6>CIP13 :</h6> {rowData?.cip13}
                </div>
                <div style={{marginBottom: 10}}>
                    <h6>En stock :</h6> {rowData?.stock}
                </div>
                <div style={{marginBottom: 10}}>
                    <h6>Quantité :</h6>
                    <br/>
                    <InputGroup>
                        <InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
                        <Input
                            className={"custom-input-number"}
                            value={value}
                            onChange={(value) => {
                                if (value > 0 && value <= rowData?.stock) {
                                    setValue(value);
                                } else {
                                    toast.error("Quantité invalide");
                                }
                            }}
                        />
                        <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
                    </InputGroup>
                </div>
            </ConfirmationModal>
            <Button
                onClick={() => {
                    console.log("ADD TO CART", rowData);
                    setModalAddOpen(true);
                }}
                appearance="primary"
            >
                <AddOutlineIcon/>
            </Button>
        </Table.Cell>
    );
};

const HasContentCell = ({rowData, dataKey, ...props}) => {
    return (
        <Table.Cell {...props}>
            {rowData[dataKey] ? (
                <CheckOutlineIcon style={{color: "green"}}/>
            ) : (
                <CloseOutlineIcon style={{color: "red"}}/>
            )}
        </Table.Cell>
    );
};
const TypeCell = ({rowData, dataKey, ...props}) => {
    return (
        <Table.Cell {...props}>
            {rowData["categoryType"] &&
            rowData["categoryType"] !== "-" &&
            rowData["brandType"] &&
            rowData["brandType"] !== "-" &&
            rowData["brandType"] !== rowData["categoryType"] ? (
                categoriesTypes?.[rowData["categoryType"]]?.label +
                " / " +
                categoriesTypes?.[rowData["brandType"]]?.label
            ) : rowData["categoryType"] && rowData["categoryType"] !== "-" ? (
                categoriesTypes?.[rowData["categoryType"]]?.label
            ) : rowData["brandType"] && rowData["brandType"] !== "-" ? (
                categoriesTypes?.[rowData["brandType"]]?.label
            ) : (
                <CloseOutlineIcon style={{color: "red"}}/>
            )}
        </Table.Cell>
    );
};

const DeleteCell = ({
                        rowData,
                        dataKey,
                        reloadProducts,
                        setSelectedProduct,
                        setModificationOpen,
                        ...props
                    }) => {
    let params = useParams();
    let navigate = useNavigate();

    const [validationDelete, setValidationDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const {getToken} = useAuthentification();

    //Mod
    function modifyProduct() {
        setSelectedProduct(rowData[dataKey]);
        setModificationOpen(true);
    }

    return (
        <>
            <ConfirmationModal
                open={validationDelete}
                loading={loading}
                setOpen={setValidationDelete}
                title="Suppression d'un produit"
                message="Voulez-vous vraiment supprimer ce produit ? Cette action est irréversible. Le produit pourra revenir en non visible lors de l'actualisation des stocks winpharma."
                acceptText="Valider la suppression du produit"
                acceptAction={() => {
                    setLoading(true);
                    const token = getToken();
                    simpleFetch(
                        "/products/" + rowData[dataKey],
                        "DELETE",
                        null,
                        token
                    ).then((res) => {
                        console.log(res);
                        reloadProducts(() => {
                            setValidationDelete(false);
                            setModificationOpen(false);
                            setLoading(false);

                            toast.success("Produit supprimé avec succès");
                        });
                    });
                }}
            />

            <Table.Cell
                {...props}
                style={{
                    paddingTop: 0,
                    padding: 0,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Button
                    //   onClick={() => navigate(`${rowData[dataKey]}`)}
                    onClick={() => {
                        setLoading(false);
                        setValidationDelete(true);
                    }}
                    appearance="ghost"
                    color="red"
                >
                    <TrashIcon/>
                </Button>
                <Button
                    onClick={() => {
                        if (params?.userId) {
                            navigate(`${params?.userId}/${rowData[dataKey]}`);
                        } else {
                            navigate(`${rowData[dataKey]}`);
                        }
                        modifyProduct(true);
                    }}
                    appearance="primary"
                >
                    <EditIcon/>
                </Button>
            </Table.Cell>
        </>
    );
};

export default ProductList;
