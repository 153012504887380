import React, {useEffect, useState} from "react";
import {useCalling} from "../../../Context/CallContext";
import {useAuthentification} from "../../../Context/AuthContext";
import * as Video from "twilio-video";
import {Button, Input, InputGroup, Table} from "rsuite";
import simpleFetch from "../../../data/simpleFetch";
import useFetch from "../../../data/useFetch";
import fetchDocument from "../../../data/fetchDocument";
import EditUserModal from "../EditUserModal";
import ReloadIcon from "@rsuite/icons/Reload";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import PlusIcon from "@rsuite/icons/Plus";
import {toast} from "react-toastify";
import ConfirmationModal from "../../../Components/ConfirmationModal";
import {useNavigate} from "react-router-dom";
import "./twilioView.scss"

const TwilioView = () => {
    const {
        room,
        closeCommunicationWindow,
        setLocalTrack,
        localTrack,
        setRoom,
        cart,
        getCart,
        userId,
    } = useCalling();
    const {getToken} = useAuthentification();
    const token = getToken();
    const [localTrackEnabled, setLocalTrackEnabled] = useState(true);
    const [localMicEnabled, setLocalMicEnabled] = useState(true);

    useEffect(() => {
        console.log("CART", cart);
    });
    useEffect(() => {
        console.warn(room);
        Video.createLocalVideoTrack().then((track) => {
            const localMediaContainer = document.getElementById("local-media");

            setLocalTrack(track);
            if (!localMediaContainer.firstChild) {
                localMediaContainer.appendChild(track.attach());
            } else {
                localMediaContainer.replaceChild(
                    track.attach(),
                    localMediaContainer.firstChild
                );
            }
            const localVideo = localMediaContainer.firstChild;

            localVideo.style.width = "180px";
        });
    }, []);

    const [user, setuser] = useState(null);
    const {
        data: documentData,
        error: documentError,
        loading: documentLoading,
    } = useFetch(`/documents/all/${user?.id}`, "GET", token, false, getToken);

    function getDocuments() {
        if (documentData) {
            return documentData;
        } else return [];
    }

    useEffect(() => {
        extractUser();
    }, [room.name]);

    function extractUser() {
        let user = room.name.split("/")[1];
        if (user.includes("user")) {
            user = user.replace("user", "");
            console.log(user);
        }
        simpleFetch(`/users/${user}`, "GET", null, token).then((user) => {
            setuser(user);
        });
    }

    const [showModal, setShowModal] = useState(false);
    let navigate = useNavigate();

    return (
        <div className="twilio-view">
            <EditUserModal
                open={showModal}
                setOpen={setShowModal}
                user={user}
                reloadUser={extractUser}
            />

            <div className="medias">
                <h5 className="subtitle">Camera du client:</h5>
                <div id="remote-media"/>
                <h5 className="subtitle">Retour camera:</h5>
                <div id="local-media"/>
            </div>

            <div className="info-section">
                <div className="twilio-section">
                    <h1 className="title">Informations diverses</h1>
                    Salle de visio-conférence : {room.name}{" "}
                    {room.name.search("/user") !== -1
                        ? "(Sous rendez-vous)"
                        : "(Sans rendez-vous)"}{" "}
                    <br/>
                    <div className="btn-section">
                        <Button
                            onClick={() => {
                                setLocalTrackEnabled(!localTrackEnabled);
                                if (!localTrackEnabled) {
                                    console.log(localTrack);
                                    room.localParticipant.videoTracks.forEach((publication) => {
                                        localTrack.restart();

                                        publication.track.enable();
                                    });
                                } else {
                                    localTrack.stop();
                                    room.localParticipant.videoTracks.forEach((publication) => {
                                        localTrack.stop();
                                        publication.track.disable();
                                    });
                                }
                            }}
                            style={{marginRight: 10}}
                        >
                            {localTrackEnabled ? "Désactiver la vidéo" : "Activer la vidéo"}
                        </Button>
                        <Button
                            onClick={() => {
                                setLocalMicEnabled(!localMicEnabled);
                                // localTrack.enableAudio(!localMicEnabled);
                                if (!localMicEnabled) {
                                    room.localParticipant.audioTracks.forEach((publication) => {
                                        publication.track.enable();
                                    });
                                } else {
                                    room.localParticipant.audioTracks.forEach((publication) => {
                                        publication.track.disable();
                                    });
                                }
                            }}
                            style={{marginRight: 10}}
                        >
                            {localMicEnabled ? "Désactiver le micro" : "Activer le micro"}
                        </Button>
                        <Button
                            onClick={() => {
                                closeCommunicationWindow();
                                room.localParticipant.videoTracks.forEach((publication) => {
                                    publication.track.stop();
                                });
                                room.localParticipant.audioTracks.forEach((publication) => {
                                    publication.track.stop();
                                });
                                room.disconnect();
                                setRoom(null);
                            }}
                            style={{marginRight: 10}}
                        >
                            Fermer la communication
                        </Button>
                    </div>
                </div>
                <div className="twilio-section">
                    <h1 className="title">Informations de l'utilisateur</h1>
                    Nom : {user?.firstName || "..."}
                    <br/>
                    Prénom : {user?.lastName || "..."}
                    <br/>
                    Email : {user?.email || "..."}
                    <br/>
                    Téléphone : {user?.phone || "..."}
                    <br/>
                    Date de naissance : {user?.birthDate || "..."}
                    <br/>
                    Allergies : {user?.allergies || "..."}
                    <br/>
                    Taille : {user?.size || "..."}
                    <br/>
                    Poids : {user?.weight || "..."}
                    <br/>
                    Token Notification : {user?.expoPushToken || "..."}
                    <br/>
                    Id Stripe : {user?.stripeId || "..."}
                    <br/>
                    Adresse : {user?.address?.line1 || "..."} {user?.address?.line2}
                    <br/>
                    Ville : {user?.address?.city || "..."}
                    <br/>
                    Code postal : {user?.address?.postalCode || "..."}
                    <br/>
                    Pays : {user?.address?.country || "..."}
                    <br/>
                    Indications : {user?.address?.indications || "..."}
                    <br/>
                    <Button appearance="ghost" onClick={() => setShowModal(true)}>
                        Modifier les informations
                    </Button>
                </div>
                <div className="twilio-section">
                    <div className="cart-section-top">
                        <h1 className="title">Panier de l'utilisateur</h1>
                        <ReloadIcon
                            fill="#3498FF"
                            onClick={() => {
                                console.log(cart);
                                getCart(userId);
                                toast.success("Panier actualisé");
                            }}
                            style={{marginLeft: 20, height: 25, width: 25}}
                        />

                        <PlusIcon
                            onClick={() => {
                                navigate(`/cart`);
                                toast.info(
                                    "Selectionnez le produit dans la liste des produits, puis cliquez sur le bouton 'Ajouter au panier'"
                                );
                            }}
                            fill="#ffffff"
                            size="10em"
                            style={{
                                backgroundColor: "#3498FF",
                                padding: 5,
                                width: 30,
                                height: 30,
                                borderRadius: 100,
                                marginLeft: 20,
                            }}
                        />
                    </div>

                    <div>
                        {cart?.cartToProducts?.length > 0
                            ? cart.cartToProducts?.map((item,index) => (
                                <ItemLine key={index} item={item} reload={() => getCart(userId)}/>
                            ))
                            : "Aucun produit dans le panier de l'utilisateur"}
                    </div>
                </div>
                <div style={{flex: 1}}>
                    <h1 className="title">Documents de l'utilisateur</h1>
                    <Table
                        flexGrow={1}
                        headerHeight={45}
                        autoHeight
                        affixHeader
                        data={getDocuments()}
                        wordWrap="break-word"
                        loading={documentLoading}
                        renderEmpty={() => <div>Aucun document n'a été trouvé.</div>}
                    >
                        <Table.Column align="center" fixed verticalAlign={"middle"}>
                            <Table.HeaderCell>Id document</Table.HeaderCell>
                            <Table.Cell dataKey="id"/>
                        </Table.Column>
                        <Table.Column
                            align="center"
                            fixed
                            flexGrow={1}
                            verticalAlign={"middle"}
                        >
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell dataKey="type"/>
                        </Table.Column>
                        <Table.Column
                            align="center"
                            fixed
                            flexGrow={1}
                            verticalAlign={"middle"}
                        >
                            <Table.HeaderCell>Nom</Table.HeaderCell>
                            <Table.Cell dataKey="name"/>
                        </Table.Column>
                        <Table.Column
                            align="center"
                            fixed
                            flexGrow={1}
                            verticalAlign={"middle"}
                        >
                            <Table.HeaderCell>Date d'ajout</Table.HeaderCell>
                            <DateCell dataKey="createdAt"/>
                        </Table.Column>
                        <Table.Column align="center" fixed verticalAlign={"middle"}>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            <ActionCell dataKey="id" token={token}/>
                        </Table.Column>
                    </Table>
                </div>
            </div>
        </div>
    );
}

function ItemLine({item, reload}) {
    const {updateCart, removeFromCart} = useCalling();
    const [value, setValue] = useState(item?.amount);
    const handleMinus = () => {
        if (value > 1) {
            setValue(parseInt(value, 10) - 1);
        } else {
            toast.error("Vous ne pouvez pas insérer une quantité négative");
        }
    };
    const handlePlus = () => {
        if (value < item?.product?.stock) {
            setValue(parseInt(value, 10) + 1);
        } else {
            toast.error(
                "Vous ne pouvez pas insérer une quantité supérieure au stock. Le client se retrouverait avec un produit en rupture de stock et ne pourrait pas finaliser sa commande."
            );
        }
    };
    console.log(item);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div
            style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 10,
                maxWidth: 500,
            }}
        >
            {item?.product?.name} - Quantité : {item?.amount}
            <div
                style={{
                    minWidth: 100,
                    maxWidth: 100,
                    width: 100,
                }}
            >
                <ConfirmationModal
                    open={modalOpen}
                    title={"Modification d'un produit dans le panier du client"}
                    setOpen={setModalOpen}
                    acceptText={"Valider la modification"}
                    cancelAction={() => {
                        setModalOpen(false);
                        reload();
                    }}
                    acceptAction={() => updateCart(item?.product?.id, value)}
                >
                    <div style={{marginBottom: 10}}>
                        <h6>Produit:</h6> {item?.product?.name}
                    </div>
                    <div style={{marginBottom: 10}}>
                        <h6>CIP13 :</h6> {item?.product?.cip13}
                    </div>
                    <div style={{marginBottom: 10}}>
                        <h6>En stock :</h6> {item?.product?.stock}
                    </div>
                    <div style={{marginBottom: 10}}>
                        <h6>Quantité :</h6>
                        <br/>
                        <InputGroup>
                            <InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
                            <Input
                                className={"custom-input-number"}
                                value={value}
                                onChange={(value) => {
                                    if (value > 0 && value <= item?.product?.stock) {
                                        setValue(value);
                                    } else {
                                        toast.error("Quantité invalide");
                                    }
                                }}
                            />
                            <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
                        </InputGroup>
                    </div>
                </ConfirmationModal>

                <Button
                    onClick={() => removeFromCart(item?.product?.id)}
                    appearance="ghost"
                    color="red"
                    style={{marginInline: 10}}
                >
                    <TrashIcon size="10em"/>
                </Button>

                <Button
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    appearance="primary"
                >
                    <EditIcon size="10em"/>
                </Button>
            </div>
        </div>
    );
}

function DateCell({rowData, dataKey, ...props}) {
    const date = new Date(rowData[dataKey]);

    return (
        <Table.Cell {...props}>
            {date.getTime() > 0 ? date.toLocaleDateString() : "-"}
        </Table.Cell>
    );
}

const ActionCell = ({rowData, dataKey, ...props}) => {
    const {getToken} = useAuthentification();
    const token = getToken();
    return (
        <>
            <Table.Cell {...props}>
                <Button
                    onClick={() => {
                        const doc = fetchDocument(
                            `/documents/download/${rowData[dataKey]}`,
                            token
                        ).then((res) => {
                            window.open(res);
                        });
                    }}
                    appearance="primary"
                >
                    Ouvrir
                </Button>
            </Table.Cell>
        </>
    );
};

export default TwilioView;
