import { useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Sidenav, Nav } from "rsuite";
import { useAuthentification } from "../Context/AuthContext";

function SidebarNavigation(props) {
  const { logout } = useAuthentification();
  let navigate = useNavigate();

  let location = useLocation();
  useEffect(() => console.log(location), [location]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flex: 1,
        flexGrow: 1
      }}
    >
      <Sidenav
        activeKey={location.pathname.split("/")[1]}
        activeColor={"#ACE4E6"}
        style={{ flexGrow: 1 }}
      >
        <Sidenav.Body>
          <Nav>
            <div style={{width:"100%", boxSizing:"border-box",padding:5}}>
              <img style={{width:"100%"}} src={"/banniere.png"} alt={""} role={"presentation"}/>
            </div>
            <Nav.Item eventKey="orders" onClick={() => navigate("orders")}>
              Gestion des commandes
            </Nav.Item>
            <Nav.Item
              eventKey="telepharmacie"
              onClick={() => navigate("telepharmacie")}
            >
              Télépharmacie
            </Nav.Item>
            <Nav.Item eventKey="products" onClick={() => navigate("products")}>
              Liste des produits
            </Nav.Item>
            <Nav.Item
              eventKey="categories"
              onClick={() => navigate("categories")}
            >
              Liste des catégories
            </Nav.Item>
            <Nav.Item
              eventKey="coupons"
              onClick={() => navigate("coupons")}
            >
              Coupons
            </Nav.Item>
            <Nav.Item eventKey="paiement" onClick={() => navigate("paiement")}>
              Lien de paiement
            </Nav.Item>
            <Nav.Item
              eventKey="notifications"
              onClick={() => navigate("notifications")}
            >
              Notifications
            </Nav.Item>

            <Nav.Item eventKey="logout" onClick={logout}>
              Se déconnecter
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
}

export default SidebarNavigation;
