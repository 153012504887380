import React, {useState} from "react";
import {
    Button,
    Table,
    Form,
    Schema,
    Checkbox,
    Modal,
    Radio,
    RadioGroup,
} from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";

import useFetch from "../../../data/useFetch";
import {useAuthentification} from "../../../Context/AuthContext";
import {useEffect} from "react";
import simpleFetch from "../../../data/simpleFetch";

import {useCalling} from "../../../Context/CallContext";

import {toast} from "react-toastify";
import {useRef} from "react";
import {searchData} from "../../../Components/searchData";
import "./appointmentList.scss";

const AppointmentList = () => {
    const {getToken} = useAuthentification();
    const token = getToken();
    const [rooms, setRooms] = useState([]);

    const {data, error, requestData} = useFetch(
        "/time-slots/all",
        "GET",
        token,
        undefined,
        true,
        getToken
    );

    useEffect(() => {
        let interval = setInterval(() => requestData(), 3000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        if (data !== rooms) {
            setRooms(data);
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            toast.error(
                "Une erreur est survenue lors de la récupération de la liste des rendez-vous"
            );
        }
    }, [error]);
    const {joinRoom, room} = useCalling();

    // Time slots
    const {
        data: timeslots,
        loading: timeslotsLoading,

        requestData: requestTimeslots,
    } = useFetch("/time-slots", "GET", token);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [sortLoading, setSortLoading] = useState(false);
    // search
    const [addHoursModal, setAddHoursModal] = useState(false);
    const [search, setSearch] = useState("");

    const [onlyBooked, setOnlyBooked] = useState(false);
    const getData = () => {
        let newData = timeslots;

        if (newData) {
            newData = newData?.map((item) => {
                if (onlyBooked && !item?.userId) {
                    return null;
                }
                return {
                    id: item.id,
                    beginDate: item.beginDate,
                    endDate: item.endDate,
                    userName: item?.user
                        ? (item?.user?.firstName ? item?.user?.firstName : "?") +
                        " " +
                        (item?.user?.lastName ? item?.user?.lastName : "?")
                        : false,
                };
            });

            if (sortColumn && sortType) {
                newData.sort((a, b) => {
                    let x = a[sortColumn];
                    let y = b[sortColumn];
                    if (sortColumn !== "beginDate" && sortColumn !== "endDate") {
                        if (typeof x === "string") {
                            x = x.charCodeAt();
                        }
                        if (typeof y === "string") {
                            y = y.charCodeAt();
                        }
                        if (sortType === "asc") {
                            return x - y;
                        } else {
                            return y - x;
                        }
                    } else {
                        if (sortType === "asc") {
                            return new Date(x) - new Date(y);
                        } else {
                            return new Date(y) - new Date(x);
                        }
                    }
                });
            }

            return searchData(newData, search);
        }

        return [];
    };
    const handleSortColumn = (sortColumn, sortType) => {
        setSortLoading(true);
        setTimeout(() => {
            setSortLoading(false);
            toast.success("Le tableau à été trié !", {autoClose: 2000});

            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const [formAdd, setFormAdd] = useState({
        day: null,
        start: null,
        end: null,
        duration: "5",
    });

    const formAddRef = useRef(null);
    const formAddModel = Schema.Model({
        day: Schema.Types.StringType().isRequired("Ce champs est réquis"),
        start: Schema.Types.StringType().isRequired("Ce champs est réquis"),
        end: Schema.Types.StringType().isRequired("Ce champs est réquis"),
        duration: Schema.Types.StringType().isRequired("Ce champs est réquis"),
    });

    function submitAddhours() {
        if (formAddRef.current.check()) {
            console.log(formAdd);
            let dateStart = new Date(formAdd.day + " " + formAdd.start);
            let dateEnd = new Date(formAdd.day + " " + formAdd.end);

            console.log("START", dateStart);
            console.log("END", dateEnd);
            const duration = parseInt(formAdd.duration);
            console.log("DURATION", duration);
            dateEnd.setMinutes(dateEnd.getMinutes() - duration);
            console.log("END END", dateEnd);
            let created = 0;
            while (dateStart <= dateEnd) {
                created += 1;
                let end = new Date(dateStart);
                end.setMinutes(end.getMinutes() + duration);
                console.log(
                    "BEBEBE",
                    dateStart
                        .toString()
                        .replace("GMT+0200 (heure d’été d’Europe centrale)", ""),
                    end.toString().replace("GMT+0200 (heure d’été d’Europe centrale)", "")
                );
                simpleFetch(
                    "/time-slots",
                    "POST",
                    {
                        beginDate: dateStart
                            .toString()
                            .replace("GMT+0200 (heure d’été d’Europe centrale)", ""),
                        endDate: end
                            .toString()
                            .replace("GMT+0200 (heure d’été d’Europe centrale)", ""),
                        type: "MEETING",
                    },
                    token
                ).then((res) => {
                    console.log(res);
                });
                dateStart.setMinutes(dateStart.getMinutes() + duration);
            }
            if (created > 0) {
                toast.success(`${created} créneaux ont été ajoutés !`, {
                    autoClose: 2000,
                });
                requestTimeslots();
            } else {
                toast.error(`Aucun créneaux n'a été ajouté !`, {autoClose: 2000});
            }
        }
    }

    return (
        <div className="appointment-list-wrapper">
            <Modal open={addHoursModal} onClose={() => setAddHoursModal(false)}>
                <div className="modal-form-container">
                    <h1 className="title">
                        Ajout de crénaux de disponibilité
                    </h1>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Form
                            checkTrigger={"none"}
                            formValue={formAdd}
                            onChange={(formValue) => setFormAdd(formValue)}
                            model={formAddModel}
                            ref={formAddRef}
                        >
                            <Form.Group controlId="jour">
                                <Form.ControlLabel>Jour</Form.ControlLabel>
                                <Form.Control
                                    name="day"
                                    type="date"
                                    style={{width: 260}}
                                />
                            </Form.Group>
                            <Form.Group controlId="start">
                                <Form.ControlLabel> Heure de début</Form.ControlLabel>
                                <Form.Control
                                    name="start"
                                    type="time"
                                    style={{width: 260}}
                                />
                            </Form.Group>
                            <Form.Group controlId="fin">
                                <Form.ControlLabel>Heure de fin</Form.ControlLabel>
                                <Form.Control
                                    name="end"
                                    type="time"
                                    style={{width: 260}}
                                />
                            </Form.Group>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: 20,
                                }}
                            >
                                Automatiquement découper la tranche horaire en crénau de
                            </div>
                            <Form.Group controlId="duration">
                                <Form.Control name="duration" accepter={RadioGroup} inline>
                                    <Radio value="5">5 minutes</Radio>
                                    <Radio value="10">10 minutes</Radio>
                                    <Radio value="15">15 minutes</Radio>
                                    <Radio value="20">20 minutes</Radio>
                                </Form.Control>
                            </Form.Group>

                            <Button appearance="primary" onClick={submitAddhours}>
                                Ajouter les créneaux
                            </Button>
                        </Form>
                    </div>
                </div>
            </Modal>
            <div className="section-wrapper">
                <h1 className="title">Actuellement en salle d'attente</h1>
                {rooms?.length > 0 ? (
                    rooms?.map((room) => <RoomAccess key={room?.sid} room={room}/>)
                ) : (
                    <div>Il n'y a pas de patients en salle d'attente</div>
                )}
            </div>
            <div className="section-wrapper">
                <h1 className="title">Liste des rendez vous</h1>
                <Checkbox value={onlyBooked} onChange={(value) => setOnlyBooked(!value)}>
                    Montrer uniquement les rendez-vous réservés
                </Checkbox>
            </div>
            <Button className="btn-main" onClick={() => setAddHoursModal(true)}>
                Ajouter des crénaux
            </Button>
            <div className="table-wrapper">
                <Table
                    virtualized
                    fillHeight
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={timeslotsLoading || sortLoading}
                    onRowClick={(data) => {
                        console.log(data);
                        // navigate(data.id.toString());
                    }}
                    renderEmpty={() => <div>Aucun crénaux n'a été trouvé..</div>}
                >
                    <Table.Column align="center" sortable minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>Début</Table.HeaderCell>
                        <DateCell dataKey="beginDate"/>
                    </Table.Column>
                    <Table.Column align="center" sortable minWidth={150} flexGrow={1}>
                        <Table.HeaderCell>Fin</Table.HeaderCell>
                        <DateCell dataKey="endDate"/>
                    </Table.Column>
                    <Table.Column align="center" sortable minWidth={100} flexGrow={1}>
                        <Table.HeaderCell>Utilisateur</Table.HeaderCell>
                        <Table.Cell dataKey="userName"/>
                    </Table.Column>
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.Cell dataKey="id"/>
                    </Table.Column>
                    <Table.Column align="center">
                        <Table.HeaderCell>Supprimer</Table.HeaderCell>
                        <DeleteCell dataKey="id" requestTimeslots={requestTimeslots}/>
                    </Table.Column>
                </Table>
            </div>
            {room && <Button onClick={() => room.disconnect()}>Quitter</Button>}
        </div>
    );
}

function RoomAccess({room}) {
    const [isloading, setIsLoading] = useState(false);
    const {joinRoom} = useCalling();
    return (
        <div style={{alignItems: "center", fontSize: 17}}>
            Créé à {new Date(room?.dateCreated).toLocaleTimeString()}{" "}
            <Button loading={isloading} onClick={() => joinRoom(room?.uniqueName)}>
                Rejoindre
            </Button>
            <div
                id="video"
                style={{width: 200, heigth: 500, backgroundColor: "red"}}
            ></div>
        </div>
    );
}

function DateCell({rowData, dataKey, ...props}) {
    const date = new Date(rowData[dataKey]);

    return (
        <Table.Cell {...props}>
            {date.getTime() > 0
                ? date.toLocaleDateString() + " " + date.toLocaleTimeString()
                : "-"}
        </Table.Cell>
    );
}

function DeleteCell({rowData, dataKey, requestTimeslots, ...props}) {
    const {getToken} = useAuthentification();
    const token = getToken();

    function deleteSlot() {
        simpleFetch(`/time-slots/${rowData[dataKey]}`, "DELETE", null, token).then(
            (res) => {
                console.log(res);
                toast.success("Créneau supprimé");
                requestTimeslots();
            }
        );
    }

    return (
        <Table.Cell
            {...props}
            style={{
                margin: 0,

                padding: 0,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            <Button
                style={{
                    height: 30,
                    width: 30,
                    padding: 0,
                    margin: 0,
                    justifyContent: "center",
                    alignItems: "center",
                }}
                appearance="ghost"
                color="red"
                onClick={() => {
                    deleteSlot();
                }}
            >
                <TrashIcon size="10em"/>
            </Button>
        </Table.Cell>
    );
}

export default AppointmentList;
