import { APIURL } from "../env";
import { toast } from "react-toastify";
async function fetchDocument(url, token) {
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  };

  try {
    const doc = await fetch(APIURL + url, options);
    const blob = await doc.blob();
    const docUrl = URL.createObjectURL(blob);

    return docUrl;
  } catch (e) {
    toast.error("Impossible de charger le document");
    toast.error(e);
    return e;
  }
}

export default fetchDocument;
