import React, {useState, useEffect} from 'react';
import {Input, Button, Table} from 'rsuite';
import {toast} from "react-toastify";
import {useAuthentification} from '../../Context/AuthContext';
import simpleFetch from '../../data/simpleFetch';
import ConfirmationModal from '../../Components/ConfirmationModal';
import TrashIcon from "@rsuite/icons/Trash";
import FormGroup from "rsuite/esm/FormGroup";

const Coupons = () => {
    const [formValue, setFormValue] = React.useState({
        code: "",
        percentageOff: ""
    })
    const [coupons, setCoupons] = React.useState([])

    const {getToken} = useAuthentification();

    function create() {
        const token = getToken();
        simpleFetch("/coupons", "POST", formValue, token).then((data) => {
            if (data?.code) {
                toast.success("Coupon créé")
            } else {
                toast.error("Une erreur est survenue, veuillez vérifier les informations saisies")
            }
        });
    }

    function deleteCoupon(id) {
        const token = getToken();
        simpleFetch("/coupons/" + id, "DELETE", null, token).then((data) => {
            toast.success("Coupon supprimé")
            getAllCoupons()

        });
    }

    function getAllCoupons() {
        const token = getToken();
        simpleFetch("/coupons", "GET", null, token).then((data) => {
            if (data?.length >= 0) {
                setCoupons(data)

            } else {
                toast.error("Une erreur est survenue lors de la récupération des coupons. Essayez de rafraichir la page.")
            }
            getAllCoupons()
        });
    }

    useEffect(() => {
        getAllCoupons()
    }, [])

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <h1 className="title">Coupons de réduction</h1>
            <h5 className="subtitle">Ajout d'un coupon</h5>
            <FormGroup className="search-wrapper" inline>
                <Input
                    placeholder="Code"
                    value={formValue?.code}
                    className="default-input"
                    onChange={(value) => setFormValue({...formValue, code: value})}
                />
                <Input
                    placeholder="Pourcentage de réduction"
                    className="default-input"
                    value={formValue?.percentageOff}
                    onChange={(value) => setFormValue({...formValue, percentageOff: value})}
                />
                <Button className="btn-main" onClick={create}>Créer</Button>
            </FormGroup>
            <div style={{flex: 1}}>
                <Table
                    virtualized
                    fillHeight
                    cellHeight={60}
                    data={() => coupons}
                    loading={!(coupons?.length >= 0)}
                    renderEmpty={() => (
                        <div>Aucun coupon trouvé.</div>
                    )}
                >
                    <Table.Column align="center" width={130}>
                        <Table.HeaderCell>Code</Table.HeaderCell>
                        <Table.Cell dataKey="code"/>
                    </Table.Column>
                    <Table.Column align="center">
                        <Table.HeaderCell>Réduction (%)</Table.HeaderCell>
                        <Table.Cell dataKey="percentageOff"/>
                    </Table.Column>
                    <Table.Column align="center">
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <DeleteCell dataKey="id" deleteCoupon={deleteCoupon}/>
                    </Table.Column>
                </Table>
            </div>
        </div>
    );
}


const DeleteCell = ({
                        rowData,
                        dataKey,
                        deleteCoupon,
                        ...props
                    }) => {


    const [validationDelete, setValidationDelete] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <>
            <ConfirmationModal
                open={validationDelete}
                loading={loading}
                setOpen={setValidationDelete}
                title="Suppression d'un coupon"
                message="Voulez-vous vraiment supprimer ce coupon ? Cette action est irréversible."
                acceptText="Valider la suppression du coupon"
                acceptAction={() => {
                    deleteCoupon(rowData.id)
                    toast.info("Suppression en cours...")
                    setValidationDelete(false)
                }}
            />

            <Table.Cell
                {...props}
                style={{
                    paddingTop: 0,
                    padding: 0,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    display: "flex",
                }}
            >

                <Button
                    onClick={() => {
                        setLoading(false);
                        setValidationDelete(true);
                    }}
                    appearance="ghost"
                    color="red"
                >
                    <TrashIcon/>
                </Button>

            </Table.Cell>
        </>
    );
};


export default Coupons;