import React, {useState} from "react";
import {useAuthentification} from "../../Context/AuthContext";
import useFetch from "../../data/useFetch";
import {toast} from "react-toastify";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {
    Button,
    Checkbox,
    CheckboxGroup,
    Input,
    Table,
    Uploader,
    RadioGroup,
    Radio,
    SelectPicker,
} from "rsuite";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import {FaCameraRetro} from "react-icons/fa";
import FormGroup from "rsuite/esm/FormGroup";
import categoriesTypes from "../../data/categoriesType.json";
import simpleFetch from "../../data/simpleFetch";
import {useEffect} from "react";
import simpleFetchImage from "../../data/simpleFetchImage";
import EditCategoryModal from "./EditCategoryModal";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import {sortData} from "../../Components/sortData";
import {handleSortColumn} from "../../utils/handleSortColumn";

const CategoriesList = () => {
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [sortLoading, setSortLoading] = useState(false);
    const [search, setSearch] = useState("");
    const {getToken} = useAuthentification();

    const categoriesList = Object.keys(categoriesTypes);
    const [categoriesFilter, setCategoriesFiler] = useState(categoriesList);

    const handleCheckAllStatus = (value, checked) =>
        setCategoriesFiler(checked ? categoriesList : []);
    const handleChangeSortStatus = (value) => setCategoriesFiler(value);

    const token = getToken();
    const {
        data,

        loading,
        requestData: reloadCat,
    } = useFetch("/categories", "GET", token);

    const getData = () => {
        let newData = sortData(data, sortColumn, sortType);

        if (newData) {
            newData = newData?.map((item) =>
                categoriesFilter.includes(item.type) ||
                (categoriesFilter.includes("other") && !item?.type)
                    ? item
                    : {}
            );

            newData = newData.filter((item) => (item?.id ? item : null));
            if (!search) {
                return newData?.map((item) =>
                    item
                        ? {
                            ...item,
                        }
                        : null
                );
            }
            if (search) {
                return newData
                    .filter((item) => (item?.id ? item : null))
                    .filter((item) =>
                        (item?.id + item?.name)
                            ?.toString()
                            .toLowerCase()
                            .includes(search.toLowerCase())
                            ? item
                            : null
                    );
            }
        }

        return [];
    };


    useEffect(() => {
        const token = getToken();
        simpleFetch("/categories/medipim/categories", "GET", null, token)
            .then((res) => {
                if (res?.length > 0) {
                    setCatMedipimList(
                        res?.map((category) => ({
                            label: category.name,
                            value: category.id,
                        }))
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
            });
        simpleFetch("/categories/medipim/brands", "GET", null, token)
            .then((res) => {
                if (res?.length > 0) {
                    setBrandMedipimList(
                        res?.map((category) => ({
                            label: category.name,
                            value: category.id,
                        }))
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
            });
    }, []);
    const [catmedipimList, setCatMedipimList] = useState([]);
    const [brandmedipimList, setBrandMedipimList] = useState([]);

    const [createCategoryModal, setCreateCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryType, setNewCategoryType] = useState("pharmacy");
    const [newCategoryImage, setNewCategoryImage] = useState([]);
    const [newCategoryImage2, setNewCategoryImage2] = useState([]);

    const [newCatLoading, setNewCatLoading] = useState(false);
    const [newCategoryBrand, setNewCategoryBrand] = useState();
    const [newCategoryMedipim, setNewCategoryMedipim] = useState();
    const [filtersExpanded, setFiltersExpanded] = useState(false);
    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            <div>
                <h1 className="title">
                    Liste des catégorie e-Shop
                </h1>

                <div className="top-section">
                    <div className="subtitle-wrapper-filter">
                        <h5 className="subtitle">Filtres</h5>
                        <button onClick={() => setFiltersExpanded(!filtersExpanded)}
                                className={`filters-expander ${filtersExpanded ? "expanded" : ""}`}>
                            <img src={require("../../assets/icons/expand.svg").default} alt="ouvrir les filtres"/>
                        </button>
                    </div>
                    <div className={`filter-wrapper ${filtersExpanded ? "" : "reduced"}`}>
                        <Checkbox
                            indeterminate={
                                categoriesFilter.length > 0 &&
                                categoriesFilter.length < categoriesList.length
                            }
                            checked={categoriesFilter.length === categoriesList.length}
                            onChange={handleCheckAllStatus}
                        >
                            Séléctionner tout les types de catégories
                        </Checkbox>
                        <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={categoriesFilter}
                            onChange={handleChangeSortStatus}
                            className="checkboxGroup-custom"
                        >
                            {categoriesList?.map((item) => (
                                <Checkbox key={item} value={item}>
                                    {categoriesTypes[item].label}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>

                        <div>
                            <h6 className="filter-title">Recherche :</h6>
                            <FormGroup inline className="search-wrapper">
                                <Input
                                    placeholder="Nom catégorie, id"
                                    style={{maxWidth: 600, marginRight: 10}}
                                    onChange={(value) => setSearch(value)}
                                    value={search}
                                />
                                {search && (
                                    <Button
                                        appearance="ghost"
                                        color="red"
                                        onClick={() => setSearch("")}
                                    >
                                        Supprimer
                                    </Button>
                                )}
                            </FormGroup>
                        </div>
                    </div>
                    <Button
                        style={{marginBottom: 20}}
                        appearance={"primary"}
                        className="btn-main"
                        onClick={() => {
                            setNewCatLoading(false);
                            setNewCategoryImage([]);
                            setNewCategoryName("");
                            setNewCategoryType("pharmacy");
                            setCreateCategoryModal(true);
                        }}
                    >
                        Ajouter une catégorie
                    </Button>
                </div>

                <ConfirmationModal
                    title={"Création d'une catégorie"}
                    open={createCategoryModal}
                    setOpen={setCreateCategoryModal}
                    loading={newCatLoading}
                    acceptText={"Créer"}
                    acceptAction={() => {
                        setNewCatLoading(true);
                        if (!newCategoryName || !newCategoryType) {
                            toast.error("Veuillez remplir tous les champs", {
                                autoClose: 2000,
                            });
                            setNewCatLoading(false);
                            return;
                        }
                        if (newCategoryMedipim > 0 && newCategoryBrand > 0) {
                            toast.error(
                                "Veuillez choisir soit une catégorie soit une marque Medipim, pas les deux"
                            );
                            return;
                        }
                        const token = getToken();

                        const dataToSend = {
                            name: newCategoryName,
                            type: newCategoryType,
                            medipimCategoryId: newCategoryMedipim || null,
                            medipimBrandId: newCategoryBrand || null,
                        };
                        console.log("dataToSend", dataToSend);
                        simpleFetch("/categories", "POST", dataToSend, token).then((res) => {
                            console.log("CREATION", res);
                            // check if image to upload
                            console.log("IMAGE", newCategoryImage);
                            console.log("SENDING IMAGE");
                            if (newCategoryImage2.length > 0) {
                                const formData = new FormData();
                                formData.append(
                                    "image",
                                    newCategoryImage2[0]?.blobFile,
                                    newCategoryImage2[0]?.blobFile?.name
                                );
                                simpleFetchImage(
                                    `/categories/${res.id}/image2`,
                                    "POST",
                                    formData,
                                    token
                                )
                                    .then((res) => {
                                        console.log("IMAGE", res);
                                    })
                                    .catch((err) => {
                                        console.warn(err);
                                    });
                            }
                            if (newCategoryImage.length > 0) {
                                const formData = new FormData();
                                formData.append(
                                    "image",
                                    newCategoryImage[0]?.blobFile,
                                    newCategoryImage[0]?.blobFile?.name
                                );
                                simpleFetchImage(
                                    `/categories/${res.id}/image`,
                                    "POST",
                                    formData,
                                    token
                                )
                                    .then((res) => {
                                        console.log("IMAGE", res);
                                    })
                                    .catch((err) => {
                                        console.warn(err);
                                    });
                            }
                            reloadCat(() => {
                                setCreateCategoryModal(false);
                                toast.success("Catégorie créée avec succès");
                            });
                        });
                    }}
                >
                    <FormGroup style={{flexDirection: "column"}}>
                        <div>
                            <h5>Nom de catégorie :</h5>

                            <Input
                                placeholder="Nom de la catégorie"
                                style={{maxWidth: 400, marginRight: 10}}
                                onChange={(value) => setNewCategoryName(value)}
                                value={newCategoryName}
                            />
                        </div>
                        <div style={{marginTop: 20}}>
                            <h5>Image de nom catégorie :</h5>
                            <Uploader
                                fileList={newCategoryImage}
                                autoUpload={false}
                                listType="picture"
                                onChange={(img) => {
                                    setNewCategoryImage(
                                        img.length >= 1 ? [img[img.length - 1]] : []
                                    );
                                }}
                                value={newCategoryImage}
                                accept={["image/png", "image/jpeg"]}
                            >
                                <button>
                                    <FaCameraRetro/>
                                </button>
                            </Uploader>
                        </div>
                        <div style={{marginTop: 20}}>
                            <h5>Image de catégorie :</h5>
                            <Uploader
                                fileList={newCategoryImage2}
                                autoUpload={false}
                                listType="picture"
                                onChange={(img) => {
                                    setNewCategoryImage2(
                                        img.length >= 1 ? [img[img.length - 1]] : []
                                    );
                                }}
                                value={newCategoryImage2}
                                accept={["image/png", "image/jpeg"]}
                            >
                                <button>
                                    <FaCameraRetro/>
                                </button>
                            </Uploader>
                        </div>
                        <div>
                            <RadioGroup
                                inline
                                name="radioList"
                                value={newCategoryType}
                                onChange={(value) => {
                                    setNewCategoryType(value);
                                }}
                            >
                                <Radio value="pharmacy">Pharmacie</Radio>
                                <Radio value="cosmetic">Cosmétique et beauté</Radio>
                                <Radio value="food">Compléments alimentaires</Radio>
                            </RadioGroup>
                        </div>
                        <h5>Lien avec une marque ou une catégorie Medipim</h5>
                        Associez soit une catégorie soit une marque Médipim à cette catégorie.
                        <SelectPicker
                            placeholder="Séléctionner une catégorie Médipim"
                            name="category"
                            locale={{
                                searchPlaceholder: "Rechercher",
                            }}
                            data={catmedipimList}
                            block
                            onChange={(value) => setNewCategoryMedipim(value)}
                            value={newCategoryMedipim}
                        />
                        <SelectPicker
                            placeholder="Séléctionner une marque Médipim"
                            name="brands"
                            locale={{
                                searchPlaceholder: "Rechercher",
                            }}
                            data={brandmedipimList}
                            block
                            onChange={(value) => setNewCategoryBrand(value)}
                            value={newCategoryBrand}
                        />
                        {newCategoryMedipim > 0 &&
                            newCategoryBrand > 0 &&
                            "Veuillez choisir soit une catégorie ou une marque Médipim. Ne séléctionnez pas les deux."}
                    </FormGroup>
                </ConfirmationModal>
            </div>
            <div style={{flex: 1}}>
                <Table
                    virtualized
                    fillHeight
                    cellHeight={60}
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={(sortColumn, sortType) => handleSortColumn(sortColumn, sortType, setSortLoading, setSortColumn, setSortType)}
                    loading={loading || sortLoading}
                    renderEmpty={() => (
                        <div>Aucune Commande ne correspond aux critères demandé.</div>
                    )}
                >
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.Cell dataKey="id"/>
                    </Table.Column>
                    <Table.Column align="center" sortable flexGrow={1}>
                        <Table.HeaderCell>Nom</Table.HeaderCell>
                        <Table.Cell dataKey="name"/>
                    </Table.Column>
                    <Table.Column align="center">
                        <Table.HeaderCell>Image</Table.HeaderCell>
                        <HasContentCell dataKey="image"/>
                    </Table.Column>
                    <Table.Column align="center">
                        <Table.HeaderCell>Image2</Table.HeaderCell>
                        <HasContentCell dataKey="image2"/>
                    </Table.Column>
                    <Table.Column align="center" flexGrow={1} sortable>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <TypeCell dataKey="type"/>
                    </Table.Column>
                    <Table.Column align="center" width={200} sortable>
                        <Table.HeaderCell>Category Medipim</Table.HeaderCell>
                        <HasContentCell dataKey="medipimCategoryId"/>
                    </Table.Column>
                    <Table.Column align="center" width={200} sortable>
                        <Table.HeaderCell>Marque Medipim</Table.HeaderCell>
                        <HasContentCell dataKey="medipimBrandId"/>
                    </Table.Column>
                    <Table.Column align="center" width={100}>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <DeleteCell
                            reloadCat={reloadCat}
                            dataKey="id"
                            catmedipimList={catmedipimList}
                            brandmedipimList={brandmedipimList}
                        />
                    </Table.Column>
                </Table>
            </div>
        </div>
    );
}

const HasContentCell = ({rowData, dataKey, ...props}) => {
    return (
        <Table.Cell {...props}>
            {rowData[dataKey] ? (
                <CheckOutlineIcon style={{color: "green"}}/>
            ) : (
                <CloseOutlineIcon style={{color: "red"}}/>
            )}
        </Table.Cell>
    );
};
const TypeCell = ({rowData, dataKey, ...props}) => {
    return (
        <Table.Cell {...props}>
            {rowData[dataKey] && rowData[dataKey] !== "-" ? (
                categoriesTypes?.[rowData[dataKey]]?.label
            ) : (
                <CloseOutlineIcon style={{color: "red"}}/>
            )}
        </Table.Cell>
    );
};

const DeleteCell = ({
                        rowData,
                        dataKey,
                        reloadCat,
                        catmedipimList,
                        brandmedipimList,
                        ...props
                    }) => {
    const [validationDelete, setValidationDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const {getToken} = useAuthentification();

    //Modification
    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [editvalues, setEditvalues] = useState(rowData);

    return (
        <>
            <ConfirmationModal
                open={validationDelete}
                loading={loading}
                setOpen={setValidationDelete}
                title="Suppression d'une catégorie"
                message="Voulez-vous vraiment supprimer cette catégorie ? Cette action est irréversible. Tout les produits de cette catégorie seront dissocié de la catégorie et n'apparaitront plus dans l'application."
                acceptText="Valider la suppression de la catégorie"
                acceptAction={() => {
                    setLoading(true);
                    const token = getToken();
                    simpleFetch("/products/category/204", "GET", null, token).then(
                        (products) => {
                            if (products?.length > 0) {
                                toast.info(`Dissociation de ${products.length} produits`);
                                products.forEach((product) => {
                                    simpleFetch(
                                        "/products/" + product.id,
                                        "PATCH",
                                        {category: null},
                                        token
                                    );
                                });
                            }

                            simpleFetch(
                                "/categories/" + rowData[dataKey],
                                "DELETE",
                                null,
                                token
                            ).then((res) => {
                                console.log(res);
                                reloadCat(() => {
                                    setValidationDelete(false);
                                    setLoading(false);
                                    if (res?.statusCode === 500) {
                                        toast.error("Une erreur est survenue. Merci de réessayer.");
                                    } else {
                                        toast.success("Catégorie supprimée avec succès");
                                    }
                                });
                            });
                        }
                    );
                }}
            />
            {editCategoryModal && (
                <EditCategoryModal
                    isOpen={editCategoryModal}
                    setOpen={setEditCategoryModal}
                    categoryData={editvalues}
                    callBackOnModify={reloadCat}
                    categoryId={editvalues.id}
                />
            )}
            <Table.Cell
                {...props}
                style={{
                    paddingTop: 0,
                    padding: 0,
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <Button
                    //   onClick={() => navigate(`${rowData[dataKey]}`)}
                    onClick={() => {
                        setLoading(false);
                        setValidationDelete(true);
                    }}
                    appearance="ghost"
                    color="red"
                >
                    <TrashIcon size="10em"/>
                </Button>
                <Button
                    onClick={() => {
                        console.log(rowData);
                        setEditvalues(rowData);
                        setEditCategoryModal(true);
                    }}
                    appearance="primary"
                >
                    <EditIcon size="10em"/>
                </Button>
            </Table.Cell>
        </>
    );
};

export default CategoriesList;
