import React, { useState, useEffect } from "react";

import { APIURL } from "../env";
import { useNavigate } from "react-router-dom";
export default function useFetch(
  url,
  method,
  token,
  content,
  direct = true,
  getToken
) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function requestData(callBack) {
    if (getToken) {
      token = getToken();
    }
    try {
      let options = {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      };
      if (content) {
        options.body = JSON.stringify(content);
      }

      setLoading(true);
      fetch(APIURL + url, options)
        .then((response) => response?.json())
        .then((data) => {
          if (data?.statusCode === 401) {
            navigate("");
          }
          if (data?.statusCode === 500) {
            setError(data.message);
          } else {
            setError();
          }

          setData(data);
        })
        .catch(setError)
        .finally(() => {
          if (callBack) {
            setTimeout(() => callBack(), 1000);
          }
          setLoading(false);
        });
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (direct) {
      requestData();
    }
  }, [url]);

  return { data, error, loading, requestData };
}
