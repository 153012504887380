export const searchData = (data,search) => {
    if (!search) {
        return data.filter((item) => (item?.id ? item : null));
    }
    if (search) {
        return data
            .filter((item) => (item?.id ? item : null))
            .filter((item) =>
                (
                    item?.id +
                    item?.firstName +
                    item?.lastName +
                    item?.phone +
                    item?.email
                )
                    ?.toString()
                    .includes(search)
                    ? item
                    : null
            );
    }
}