import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {toast} from "react-toastify";
import {
    Modal,
    Button,
    Checkbox,
    CheckboxGroup,
    Input,
    DateRangePicker,
    Table,
} from "rsuite";
import orderStatus from "../../../data/orderStatus.json";

import useFetch from "../../../data/useFetch";
import {useAuthentification} from "../../../Context/AuthContext";
import FormGroup from "rsuite/esm/FormGroup";
import deliveryTypes from "../../../data/deliveryTypes.json";
import {sortData} from "../../../Components/sortData";
import {searchData} from "../../../Components/searchData";
import "./orderManage.scss";

const OrderManage = () => {
    const {getToken} = useAuthentification();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [sortLoading, setSortLoading] = useState(false);
    const [filtersExpanded, setFiltersExpanded] = useState(false);

    // Sort by status
    const statusTypes = Object.keys(orderStatus);
    const [sortStatus, setSortStatus] = useState(statusTypes);

    const handleCheckAllStatus = (value, checked) =>
        setSortStatus(checked ? statusTypes : []);
    const handleChangeSortStatus = (value) => setSortStatus(value);

    // Sort by delivery mode
    const deliveryModes = Object.keys(deliveryTypes);
    const [sortDeliveryModes, setSortDeliveryModes] = useState(deliveryModes);

    const handleChangeSortDeliveryModes = (value) => setSortDeliveryModes(value);

    // search
    const [search, setSearch] = useState("");
    const token = getToken();
    const {data, loading} = useFetch("/orders", "GET", token);
    let navigate = useNavigate();

    const getData = () => {
        let newData = sortData(data, sortColumn, sortType);
        if (newData) {
            newData = newData?.map((item) =>
                sortStatus.includes(item.status) &&
                sortDeliveryModes.includes(item.deliveryMode)
                    ? {
                        id: item.id,
                        firstName: item.user.firstName,
                        lastName: item.user.lastName,
                        phone: item.user.phone,
                        email: item.user.email,
                        deliveryMode: item.deliveryMode,
                        total: item.total,
                        status: item.status,
                        createdAt: item.createdAt,
                        lastModifiedAt: item.lastModifiedAt,
                    }
                    : {}
            );
            if (dateRange?.length === 2) {
                newData = newData?.map((item) =>
                    new Date(item.createdAt) >= dateRange[0] &&
                    new Date(item.createdAt) <= dateRange[1]
                        ? item
                        : {}
                );
            }

            return searchData(newData, search);
        }

        return [];
    };
    useEffect(() => {
        if (data && !loading) {
        }
    }, [data, loading]);

    const handleSortColumn = (sortColumn, sortType) => {
        setSortLoading(true);
        setTimeout(() => {
            setSortLoading(false);
            toast.success("Le tableau à été trié !", {autoClose: 2000});

            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const [dateRange, setDateRange] = useState();
    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column", height: "100%"}}>
            <div>
                <h1 className="title">Gestion des commandes</h1>
                <div className="subtitle-wrapper-filter">
                    <h5 className="subtitle">Filtres</h5>
                    <button onClick={() => setFiltersExpanded(!filtersExpanded)}
                            className={`filters-expander ${filtersExpanded ? "expanded" : ""}`}>
                        <img src={require("../../../assets/icons/expand.svg").default} alt="ouvrir les filtres"/>
                    </button>
                </div>
                <div className={`filter-wrapper ${filtersExpanded ? "" : "reduced"}`}>
                    <h6 className="filter-title">Date de la commande :</h6>
                    <DateRangePicker
                        format="dd-MM-yyyy"
                        placeholder="Selectionner une plage de date"
                        onChange={(value) => {
                            setDateRange(value);
                        }}
                        className="dateRangePicker"
                    />
                    <div>
                        <h6 className="filter-title">Statut des commandes :</h6>
                        <Checkbox
                            indeterminate={
                                sortStatus.length > 0 && sortStatus.length < statusTypes.length
                            }
                            checked={sortStatus.length === statusTypes.length}
                            onChange={handleCheckAllStatus}
                            className="checkbox-custom"
                        >
                            Sélectionner tout
                        </Checkbox>
                        <CheckboxGroup
                            inline
                            name="checkboxList"
                            value={sortStatus}
                            onChange={handleChangeSortStatus}
                            className="checkboxGroup-custom"
                        >
                            {statusTypes?.map((item) => (
                                <Checkbox key={item} value={item}>
                                    {orderStatus[item].label}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                    </div>
                    <div>
                        <h6 className="filter-title">Modes de livraison :</h6>
                        <CheckboxGroup
                            inline
                            name="checkboxListDeliveryModes"
                            value={sortDeliveryModes}
                            onChange={handleChangeSortDeliveryModes}
                            className="checkboxGroup-custom"
                        >
                            {deliveryModes?.map((item) => (
                                <Checkbox key={item} value={item}>
                                    {deliveryTypes[item].label}
                                </Checkbox>
                            ))}
                        </CheckboxGroup>
                    </div>
                    <div>
                        <h6 className="filter-title">Recherche :</h6>
                        <FormGroup className="search-wrapper" inline>
                            <Input
                                placeholder="Nom, Prénom, Email, Téléphone, Id "
                                style={{maxWidth: 400, marginRight: 10}}
                                onChange={(value) => setSearch(value)}
                                value={search}
                            />
                            {search && (
                                <Button
                                    appearance="ghost"
                                    color="red"
                                    onClick={() => setSearch("")}
                                >
                                    Supprimer
                                </Button>
                            )}
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div style={{flex: 1}}>
                <Table
                    virtualized
                    fillHeight
                    data={getData()}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    onSortColumn={handleSortColumn}
                    loading={loading || sortLoading}
                    onRowClick={(data) => {
                        navigate(data.id.toString());
                    }}
                    renderEmpty={() => (
                        <div>Aucune Commande ne correspond aux critères demandé.</div>
                    )}
                >
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.Cell dataKey="id"/>
                    </Table.Column>
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Prénom</Table.HeaderCell>
                        <Table.Cell dataKey="firstName"/>
                    </Table.Column>
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Nom</Table.HeaderCell>
                        <Table.Cell dataKey="lastName"/>
                    </Table.Column>
                    <Table.Column align="center" flexGrow={1}>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.Cell dataKey="email"/>
                    </Table.Column>
                    <Table.Column align="center" flexGrow={1}>
                        <Table.HeaderCell>Téléphone</Table.HeaderCell>
                        <Table.Cell dataKey="phone"/>
                    </Table.Column>
                    <Table.Column align="center" flexGrow={1} sortable>
                        <Table.HeaderCell>Type</Table.HeaderCell>

                        <StatusCell dataKey="deliveryMode" source={deliveryTypes}/>
                    </Table.Column>
                    <Table.Column align="center" flexGrow={1} sortable>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        {/* <Table.Cell dataKey="status" /> */}
                        <StatusCell dataKey="status" source={orderStatus}/>
                    </Table.Column>
                    <Table.Column align="center" flexGrow={1} sortable>
                        <Table.HeaderCell>Créée le</Table.HeaderCell>
                        <DateCell dataKey="createdAt"/>
                    </Table.Column>
                    <Table.Column align="center" sortable>
                        <Table.HeaderCell>Modifié le</Table.HeaderCell>
                        <DateCell dataKey="lastModifiedAt"/>
                    </Table.Column>

                    <Table.Column align="center">
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <ActionCell
                            dataKey="id"
                            style={{
                                margin: 0,
                                padding: 0,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        />
                    </Table.Column>
                </Table>
            </div>
        </div>
    );
}
const StatusCell = ({dataKey, rowData, source, ...props}) => {
    return (
        <Table.Cell {...props} wordWrap="break-word">
            {/*{rowData[dataKey]}*/}
            {source === orderStatus
                ? rowData[dataKey] === "delivering" &&
                rowData["deliveryMode"] === "pickup"
                    ? orderStatus[rowData[dataKey]]?.labelPickup
                    : orderStatus[rowData[dataKey]].label
                : source[rowData[dataKey]]?.label}
        </Table.Cell>
    );
};
const ActionCell = ({rowData, dataKey, ...props}) => {
    const [open, setOpen] = useState(false);
    let navigate = useNavigate();
    return (
        <>
            {open && (
                <Modal backdrop={true} open={open} onClose={() => setOpen(false)} onCli>
                    <Modal.Header>
                        <Modal.Title>Commande n°{rowData[dataKey]}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>{/* <Paragraph /> */}</Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setOpen(false)} appearance="subtle">
                            Annuler les changements
                        </Button>
                        <Button onClick={() => setOpen(false)} appearance="primary">
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <Table.Cell {...props}>
                {/*{rowData[dataKey]}*/}
                <Button
                    onClick={() => navigate(`${rowData[dataKey]}`)}
                    appearance="primary"
                >
                    Voir
                </Button>
            </Table.Cell>
        </>
    );
};

function DateCell({rowData, dataKey, ...props}) {
    const date = new Date(rowData[dataKey]);

    return (
        <Table.Cell {...props}>
            {date.getTime() > 0 ? date.toLocaleDateString() : "-"}
        </Table.Cell>
    );
}

export default OrderManage;
