import {toast} from "react-toastify";

export const handleSortColumn = (sortColumn, sortType, setSortLoading, setSortColumn, setSortType) => {
    console.log("sort by", sortColumn, sortType);
    setSortLoading(true);
    setTimeout(() => {
        setSortLoading(false);
        toast.success("Le tableau à été trié !", {autoClose: 2000});

        setSortColumn(sortColumn);
        setSortType(sortType);
    }, 500);
};