import React, {useEffect, useRef, useState} from "react";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import InvoiceComponent from "../../Components/InvoiceComponent";
import {useParams} from "react-router-dom";
import useFetch from "../../data/useFetch";
import {useAuthentification} from "../../Context/AuthContext";
import Table from "rsuite/Table";
import {Badge, Button} from "rsuite";
import fetchDocument from "../../data/fetchDocument";
import orderStatus from "../../data/orderStatus";
import deliveryTypes from "../../data/deliveryTypes";
import ConfirmationModal from "../../Components/ConfirmationModal";
import simpleFetch from "../../data/simpleFetch";
import {toast} from "react-toastify";

function OneOrder() {
    const invoiceRef = useRef(null);
    const [modalInvoice, setModalInvoice] = useState(false);
    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
    });

    const [cancelOrderModal, setCancelOrderModal] = useState(false);
    const [validateOrderModal, setValidateOrderModal] = useState(false);
    const {id} = useParams();

    const {getToken} = useAuthentification();
    const token = getToken();
    const {
        data,
        error,
        loading,
        requestData: reloadOrder,
    } = useFetch(`/orders/${id}`, "GET", token);
    const {
        data: documentData,
        error: documentError,
        loading: documentLoading,
    } = useFetch(`/documents/all/${data?.userId}`, "GET", token);

    const {loading: loadingValidate, requestData} = useFetch(
        `/orders/validate/${id}`,
        "PATCH",
        token,
        undefined,
        false
    );
    const {
        data: dataCancel,
        error: errorCancel,
        loading: loadingCancel,
        requestData: requestCancel,
    } = useFetch(`/orders/cancel/${id}`, "PATCH", token, undefined, false);
    useEffect(() => {
        console.log("DATA", data, documentData);
        console.log("errors", error, documentError);
    }, [data, documentData]);

    if (loading || !data) return <div>Chargement...</div>;
    const orderTotalProduct = data?.ordersToProducts
        ?.reduce((acc, cur) => acc + cur.product.price * cur.amount, 0)
        ?.toFixed(2);
    const deliveryFee = data.deliveryFee;
    const reduction = (orderTotalProduct * data.discount).toFixed(2);
    const orderTotal = (
        parseFloat(orderTotalProduct) +
        parseFloat(deliveryFee) -
        parseFloat(reduction)
    ).toFixed(2);

    function getDocuments() {
        if (documentData) {
            return documentData;
        } else return [];
    }

    function getProducts() {
        return data.ordersToProducts?.map((product) => {
            return {
                id: product.product.id,
                name: product.product.name,
                price: product.product.price,
                amount: product.amount,
                tva: product.product.tva,
                cip13: product.product.cip13,

                pricettc: product.product.price,
                total: (product.product.price * product.amount).toFixed(2),
            };
        });
    }

    const handleDownloadLabel = async () => {
        const body = new FormData();
        body.append("file", data.colissimoLabel);

        const options = {
            method: 'post',
            body: body,
            headers: {
                'Accept': 'application/pdf',
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        fetch('https://api.labelary.com/v1/printers/12dpmm/labels/4x6/0/', options)
            .then(response => response.blob())
            .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.download = 'label.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.log(error));
    }

    return (
        <div style={{width: "100%", paddingBottom: 100}}>
            <ConfirmationModal
                open={cancelOrderModal}
                setOpen={setCancelOrderModal}
                title={`Annulation de la commande ${data.id}`}
                message={`Voulez-vous vraiment annuler la commande ${data.id} ?`}
                acceptText="Annuler la commande"
                cancelText="Fermer"
                acceptAction={() => {
                    requestCancel(reloadOrder);
                    setCancelOrderModal(false);
                }}
            />
            <ConfirmationModal
                open={validateOrderModal}
                setOpen={setValidateOrderModal}
                title={`Validation de la commande ${data.id}`}
                loading={loadingValidate}
                acceptAction={() => {
                    console.log("VALIDATION ASEKD", data.id, id);

                    if (data.deliveryMode !== "empty") {
                        requestData(reloadOrder);
                    } else {
                        const token = getToken();
                        console.log("HEY HEY HEY", id);
                        simpleFetch(
                            "/orders/" + id,
                            "PATCH",
                            {status: "completed"},
                            token
                        ).then(() => {
                            reloadOrder();
                            toast.success("Le lien de paiement à été marqué comme terminé !");
                        });
                    }
                    requestData(reloadOrder);
                    setValidateOrderModal(false);
                }}
            >
                Voulez-vous vraiment valider la commande ? <br/>
                {data.status === "payment_pending" &&
                    "\nL'utilisateur n'a pas encore payé cette commande. Si vous l'acceptez maintenant il n'aura pas à le faire."}
                {data.status === "payment_pending" && <br/>}
                Il n'est pas possible d'annuler une commande qui a déjà été validée.
            </ConfirmationModal>
            <ConfirmationModal
                open={modalInvoice}
                setOpen={setModalInvoice}
                title={`Facture commande ${data.id}`}
                acceptText={"Imprimer la facture"}
                cancelText={"Fermer"}
                acceptAction={handlePrint}
                notACallBack
            >
                <ReactToPrint content={() => invoiceRef.current}/>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            borderWidth: 1,
                            borderBlockColor: "grey",
                            borderColor: "grey",
                            borderStyle: "solid",
                            width: 440,
                            alignSelf: "center",
                        }}
                    >
                        <InvoiceComponent
                            ref={invoiceRef}
                            order={data}
                            price={{
                                totalProduct: orderTotalProduct,
                                reduction: reduction,
                                deliveryFee: deliveryFee,
                                orderTotal: orderTotal,
                            }}
                        />
                    </div>
                </div>
            </ConfirmationModal>
            <h1 className="title">Commande n°{id}</h1>
            <hr/>
            <div>
                <h4 className="subtitle" style={{display: "flex", alignItems: "center", verticalAlign: "center"}}>
                    <Badge
                        style={{
                            background: orderStatus[data.status]?.color, //"#4caf50",
                            width: 20,
                            height: 20,
                            marginRight: 10,
                        }}
                    />
                    {data.status === "delivering" && data.deliveryMode === "pickup"
                        ? orderStatus[data.status]?.labelPickup
                        : orderStatus[data.status]?.label}
                </h4>

                <p>
                    Mode de livraison : {deliveryTypes[data.deliveryMode]?.label}
                    <br/>
                    {/* État de la commande :
        <br /> */}
                    Date de création : {new Date(data.createdAt).toLocaleDateString()}
                    <br/>
                    Derniere modification :{" "}
                    {new Date(data.lastModifiedAt).toLocaleDateString()}
                    <div style={{marginTop: 20}}>
                        {["payment_pending", "admin_approval_pending"].includes(
                            data.status
                        ) && (
                            <Button
                                appearance="ghost"
                                loading={loadingValidate || loadingCancel || loading}
                                style={{marginRight: 10}}
                                onClick={() => {
                                    setCancelOrderModal(true);
                                }}
                            >
                                Annuler la commande
                            </Button>
                        )}
                        {["admin_approval_pending"].includes(data.status) && (
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    setValidateOrderModal(true);
                                }}
                                loading={loadingValidate || loadingCancel || loading}
                            >
                                Valider la commande
                            </Button>
                        )}
                    </div>
                    <div style={{marginTop: 20}}>
                        {data.status === "pending" && (
                            <Button
                                appearance="primary"
                                color={"red"}
                                loading={loadingValidate || loadingCancel || loading}
                                onClick={() => {
                                    simpleFetch(
                                        "/orders/pickup/waiting/" + id,
                                        "PATCH",
                                        undefined,
                                        token
                                    ).then((res) => {
                                        reloadOrder();
                                        toast.success(
                                            "Le status de la commande à été modifié. Votre client à été informé avec une notification."
                                        );
                                    });
                                }}
                            >
                                La commande est prête à être récupérée !
                            </Button>
                        )}

                        {["pickup_waiting", "delivering"].includes(data.status) && (
                            <Button
                                appearance="primary"
                                loading={loadingValidate || loadingCancel || loading}
                                onClick={() => {
                                    simpleFetch(
                                        "/orders/" + id,
                                        "PATCH",
                                        {status: "completed"},
                                        token
                                    ).then(() => {
                                        reloadOrder();
                                    });
                                }}
                            >
                                La commande à été{" "}
                                {data.status === "pickup_waiting" ? "récupérée" : "livrée"}
                            </Button>
                        )}

                        {["delivering", "completed"].includes(data.status) &&
                            data.deliveryMode === "parcel" && (
                                <Button
                                    style={{marginLeft: 10}}
                                    appearance="primary"
                                    loading={loadingValidate || loadingCancel || loading}
                                    onClick={handleDownloadLabel}
                                >
                                    Télécharger l'étiquette
                                </Button>
                            )}
                    </div>
                </p>
            </div>
            <hr/>
            <div style={{display: "flex", flexWrap: "wrap", width: "100%", gap: 14}}>
                <div style={{flex: 1, minWidth: 300}}>
                    <h5 className="subtitle">Informations sur l'utilisateur</h5>
                    <p>
                        Nom : {data.user.lastName || "-"}
                        <br/>
                        Prénom : {data.user.firstName || "-"}
                        <br/>
                        Email : {data.user.email || "-"}
                        <br/>
                        Téléphone : {data.user.phone || "-"}
                    </p>
                    <h5 className="subtitle">Informations de livraison</h5>
                    <p>
                        {data.address?.line1 && data.address.line1}
                        {data.address?.line1 && <br/>}
                        {data.address?.line2 && data.address.line2}
                        {data.address?.line2 && <br/>}
                        {data.address.postalCode} {data.address.city} {data.address.country}
                        {(data.address.postalCode ||
                            data.address.city ||
                            data.address.country) && <br/>}
                        Indications : {data.address?.indications || "-"}
                    </p>
                </div>

                <div style={{flex: 1, minWidth: 300}}>
                    <h5 className="subtitle">Documents</h5>
                    <Table
                        width={"100%"}
                        flexGrow={1}
                        headerHeight={45}
                        autoHeight
                        affixHeader
                        data={getDocuments()}
                        wordWrap="break-word"
                        loading={loading || documentLoading}
                        renderEmpty={() => <div>Aucun document n'a été trouvé.</div>}
                    >
                        <Table.Column align="center" fixed verticalAlign={"middle"}>
                            <Table.HeaderCell>Id document</Table.HeaderCell>
                            <Table.Cell dataKey="id"/>
                        </Table.Column>
                        <Table.Column
                            align="center"
                            fixed
                            flexGrow={1}
                            verticalAlign={"middle"}
                        >
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.Cell dataKey="type"/>
                        </Table.Column>
                        <Table.Column
                            align="center"
                            fixed
                            flexGrow={1}
                            verticalAlign={"middle"}
                        >
                            <Table.HeaderCell>Nom</Table.HeaderCell>
                            <Table.Cell dataKey="name"/>
                        </Table.Column>
                        <Table.Column
                            align="center"
                            fixed
                            flexGrow={1}
                            verticalAlign={"middle"}
                        >
                            <Table.HeaderCell>Date d'ajout</Table.HeaderCell>
                            <DateCell dataKey="createdAt"/>
                        </Table.Column>
                        <Table.Column align="center" fixed verticalAlign={"middle"}>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            <ActionCell dataKey="id" token={token}/>
                        </Table.Column>
                    </Table>
                </div>
            </div>
            <hr/>
            <h4 className="subtitle">Informations liées à la commande</h4>
            <Table
                width={"90%"}
                flexGrow={1}
                headerHeight={45}
                autoHeight
                affixHeader
                data={getProducts()}
                wordWrap="break-word"
                loading={loading}
                renderEmpty={() => <div>Aucun produit trouvé.</div>}
            >
                <Table.Column align="center" fixed>
                    <Table.HeaderCell>Id Produit</Table.HeaderCell>
                    <Table.Cell dataKey="id"/>
                </Table.Column>
                <Table.Column align="center" flexGrow={1}>
                    <Table.HeaderCell>Code CIP13 </Table.HeaderCell>
                    <Table.Cell dataKey="cip13"/>
                </Table.Column>
                <Table.Column align="center" flexGrow={1}>
                    <Table.HeaderCell>Nom </Table.HeaderCell>
                    <Table.Cell dataKey="name"/>
                </Table.Column>

                <Table.Column align="center">
                    <Table.HeaderCell>Quantité</Table.HeaderCell>
                    <Table.Cell dataKey="amount"/>
                </Table.Column>
                <Table.Column align="center" flexGrow={1}>
                    <Table.HeaderCell>Prix unitaire</Table.HeaderCell>
                    <Table.Cell dataKey="price"/>
                </Table.Column>
                <Table.Column align="center" flexGrow={1}>
                    <Table.HeaderCell>Dont TVA (%)</Table.HeaderCell>
                    <Table.Cell dataKey="tva"/>
                </Table.Column>
                <Table.Column align="center" flexGrow={1}>
                    <Table.HeaderCell>Prix total</Table.HeaderCell>
                    <Table.Cell dataKey="total"/>
                </Table.Column>
            </Table>
            <br/>
            {data?.deliveryMode === "empty" ? (
                <>
                    Commande vide.
                    <br/>
                    <strong> Total à payer :</strong> {data.priceIfEmpty}€
                </>
            ) : (
                <>
                    <br/> <strong> Total des produits :</strong> {orderTotalProduct}€
                    <br/>
                    <strong> Frais de livraison :</strong> {deliveryFee}€
                    <br/>
                    <strong> Réduction :</strong> {reduction}€
                    <br/>
                    <br/>
                    <strong> Prix total de la commande : </strong> {orderTotal}€
                </>
            )}
            <div style={{marginTop: 20}}>
                <Button appearance="primary" onClick={() => setModalInvoice(true)}>
                    Facture
                </Button>
            </div>
        </div>
    );
}

function DateCell({rowData, dataKey, ...props}) {
    const date = new Date(rowData[dataKey]);

    return (
        <Table.Cell {...props}>
            {date.getTime() > 0 ? date.toLocaleDateString() : "-"}
        </Table.Cell>
    );
}

const ActionCell = ({rowData, dataKey, token, ...props}) => {
    return (
        <>
            <Table.Cell {...props}>
                {/*{rowData[dataKey]}*/}
                <Button
                    onClick={() => {
                        const doc = fetchDocument(
                            `/documents/download/${rowData[dataKey]}`,
                            token
                        ).then((res) => {
                            window.open(res);
                        });
                    }}
                    appearance="primary"
                >
                    Ouvrir
                </Button>
            </Table.Cell>
        </>
    );
};

export default OneOrder;
