import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";

import "rsuite/dist/rsuite.min.css";
import {AuthProvider} from "./Context/AuthContext";

import "react-notifications/lib/notifications.css";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {CallProvider} from "./Context/CallContext";

ReactDOM.render(<React.StrictMode>
        <AuthProvider>
            <CallProvider>
                <Router/>
                <ToastContainer/>
            </CallProvider>
        </AuthProvider>
    </React.StrictMode>
    , document.getElementById("root"));
