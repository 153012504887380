import { APIURL } from "../env";
async function simpleFetch(endPoint, method, content, token, rawResponse) {
  let options = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  };
  if (content) {
    options.body = JSON.stringify(content);
  }

  console.log("A simple fetch was made");
  try {
    const response = await fetch(APIURL + endPoint, options);
    if (rawResponse !== undefined) {
      return response.text();
    }

    const json = await response.json();

    // console.log("RESPONSE", json);
    return json;
  } catch (e) {
    return e;
  }
}

export default simpleFetch;
