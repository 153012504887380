export const sortData = (data,sortColumn,sortType) => {
    if (sortColumn && sortType) {
        data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (sortColumn !== "lastModifiedAt" && sortColumn !== "createdAt") {
                if (typeof x === "string") {
                    x = x.charCodeAt();
                }
                if (typeof y === "string") {
                    y = y.charCodeAt();
                }
                if (sortType === "asc") {
                    return x - y;
                } else {
                    return y - x;
                }
            } else {
                if (sortType === "asc") {
                    return new Date(x) - new Date(y);
                } else {
                    return new Date(y) - new Date(x);
                }
            }
        });
    }
    return data;
}