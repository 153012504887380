import React from "react";
import { Modal, Button } from "rsuite";
function ConfirmationModal({
  open,
  setOpen,
  title,
  message,
  acceptText = "Valider",
  cancelText = "Annuler",
  acceptAction,
  cancelAction,
  children,
  loading,
  notACallBack,
}) {
  const handleClose = () => setOpen(false);
  return (
    <div className="modal-container">
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message} {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (cancelAction) cancelAction();
              handleClose();
            }}
            appearance="subtle"
          >
            {cancelText}
          </Button>
          <Button onClick={acceptAction} appearance="primary" loading={loading}>
            {acceptText}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmationModal;
