import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Button, Form, DatePicker } from "rsuite";
import simpleFetch from "../../data/simpleFetch";
import { useAuthentification } from "../../Context/AuthContext";
import { useEffect } from "react";
function EditUserModal({ open, setOpen, user, reloadUser }) {
  const { getToken } = useAuthentification();
  const [editValues, setFormValue] = useState(user);
  const [isLoading, setIsLoading] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  function handleSubmit() {
    setIsLoading(true);
    console.log(editValues);
    const token = getToken();
    simpleFetch(`/users/${user.id}`, "PATCH", editValues, token).then(() => {
      reloadUser();
      toast.success("Données utilisateur mises à jour");
    });
    setIsLoading(false);
    setOpen(false);
  }
  useEffect(() => setFormValue(user), [user]);
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        {editValues && (
          <Form
            fluid
            formValue={editValues}
            onChange={(formValue) =>
              setFormValue({
                ...editValues,
                ...formValue,
              })
            }
          >
            <Form.Group controlId="lastName">
              <Form.ControlLabel>Nom</Form.ControlLabel>
              <Form.Control name="lastName" />
            </Form.Group>
            <Form.Group controlId="firstName">
              <Form.ControlLabel>Prénom</Form.ControlLabel>
              <Form.Control name="firstName" />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.ControlLabel>Téléphone</Form.ControlLabel>
              <Form.Control name="phone" />
            </Form.Group>
            <Form.Group controlId="weight">
              <Form.ControlLabel>Poids</Form.ControlLabel>
              <Form.Control name="weight" />
            </Form.Group>
            <Form.Group controlId="size">
              <Form.ControlLabel>Taille</Form.ControlLabel>
              <Form.Control name="size" />
            </Form.Group>
            <Form.Group controlId="treatments">
              <Form.ControlLabel>Traitements</Form.ControlLabel>
              <Form.Control name="treatments" />
            </Form.Group>
            <Form.Group controlId="allergies">
              <Form.ControlLabel>Allergies</Form.ControlLabel>
              <Form.Control name="allergies" />
            </Form.Group>
            <Form.Group controlId="birthDate">
              <Form.ControlLabel>Date de naissance</Form.ControlLabel>
              <Form.Control
                name="birthDate"
                accepter={DatePicker}
                locale="fr"
              />
            </Form.Group>
            <Form
              formValue={editValues.address}
              onChange={(formValue) =>
                setFormValue({
                  ...editValues,
                  address: formValue,
                })
              }
            >
              <Form.Group controlId="line1">
                <Form.ControlLabel>Adresse Ligne 1</Form.ControlLabel>
                <Form.Control name="line1" />
              </Form.Group>
              <Form.Group controlId="line2">
                <Form.ControlLabel>Adresse Ligne 2</Form.ControlLabel>
                <Form.Control name="line2" />
              </Form.Group>
              <Form.Group controlId="city">
                <Form.ControlLabel>Ville</Form.ControlLabel>
                <Form.Control name="city" />
              </Form.Group>
              <Form.Group controlId="postalCode">
                <Form.ControlLabel>Code postal</Form.ControlLabel>
                <Form.Control name="postalCode" />
              </Form.Group>
              <Form.Group controlId="country">
                <Form.ControlLabel>Pays</Form.ControlLabel>
                <Form.Control name="country" />
              </Form.Group>
              <Form.Group controlId="indications">
                <Form.ControlLabel>Indications</Form.ControlLabel>
                <Form.Control name="indications" />
              </Form.Group>
            </Form>
            <br />
            <Button
              appearance="primary"
              style={{ marginRight: 10 }}
              loading={isLoading}
              onClick={handleSubmit}
            >
              SAUVEGARDER
            </Button>
          </Form>
        )}
        <Button onClick={() => console.log(editValues)}>ANNULER</Button>
      </Modal>
    </div>
  );
}

export default EditUserModal;
