import React, { useState } from "react";
import { Button, Input, InputGroup } from "rsuite";

import { BsFillPersonFill } from "react-icons/bs";
import banniere from "../assets/banniere.png";
import { AiFillEye, AiFillEyeInvisible, AiFillLock } from "react-icons/ai";
import { useAuthentification } from "../Context/AuthContext";
const styles = {
  width: 300,
  marginBottom: 10,
};

function Login(props) {
  const { login } = useAuthentification();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passVisible, setPassVisible] = useState(false);
  function handleChange() {
    setPassVisible(!passVisible);
  }
  const [popUpVisible, setPopUpVisibility] = useState(false);
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100vh",
      }}
    >
      <img src={banniere} style={{ width: 300 }} />
      <h2 style={styles}>
        {popUpVisible ? "Recuperation de mot de passe" : "Connexion"}
      </h2>
      <InputGroup style={styles}>
        <InputGroup.Addon>
          <BsFillPersonFill />
        </InputGroup.Addon>
        <Input
          value={email}
          onChange={setEmail}
          placeholder={"Adresse e-mail"}
        />
      </InputGroup>
      {!popUpVisible && (
        <InputGroup style={styles}>
          <InputGroup.Addon>
            <AiFillLock />
          </InputGroup.Addon>
          <Input
            type={passVisible ? "text" : "password"}
            value={password}
            onChange={setPassword}
            placeholder={"Mot de passe"}
          />
          <InputGroup.Button onClick={handleChange}>
            {passVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
          </InputGroup.Button>
        </InputGroup>
      )}
      <Button
        appearance="primary"
        onClick={() => {
          if (!popUpVisible) login(email, password);
        }}
      >
        {popUpVisible ? "Envoyer un mail" : "Se connecter"}
      </Button>

      <a
        style={{ fontStyle: "italic", marginTop: 20, cursor: "pointer" }}
        onClick={() => {
          setPopUpVisibility(!popUpVisible);
        }}
      >
        {/* {popUpVisible ? "Retour à la connexion" : "Mot de passe oublié"} */}
      </a>
    </div>
  );
}

export default Login;
