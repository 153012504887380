import React, {createContext, useContext, useState, useEffect} from "react";
import simpleFetch from "../data/simpleFetch";
import {useAuthentification} from "./AuthContext";
import * as Video from "twilio-video";
import {toast} from "react-toastify";

const CallContext = createContext({
    communicationOpen: false,
    openCommunicationWindow: (token) => {
    },
    closeCommunicationWindow: () => {
    },
    token: "",
    setToken: (token) => {
    },
    room: null,
    setRoom: (roomName) => {
    },
    joinRoom: (roomName) => {
    },
    setLocalTrack: (track) => {
    },
    localTrack: null,
    cart: {},
    userId: "",
    setCart: (cart) => {
    },
    getCart: (userId) => {
    },
    addToCart: (productId, quantity) => {
    },
    removeFromCart: (productId) => {
    },
    updateCart: (productId, quantity) => {
    },
});

// create context
function CallProvider({children}) {
    const {getToken} = useAuthentification();
    const [communicationOpen, setCommunicationOpen] = useState(false);
    const [room, setRoom] = useState(null);

    const [localTrack, setLocalTrack] = useState(null);

    function openCommunicationWindow() {
        setCommunicationOpen(true);
    }

    function closeCommunicationWindow() {
        setCommunicationOpen(false);
    }

    async function requestRoomToken(roomName) {
        const token = getToken();
        const roomT = await simpleFetch(
            "/time-slots/join-room",
            "POST",
            {
                roomName: roomName,
            },
            token,
            true
        );

        return roomT;
    }

    function joinRoom(roomName) {
        requestRoomToken(roomName).then((roomToken) => {
            Video.connect(roomToken).then((room) => {
                setRoom(room);
                openCommunicationWindow(true);
                console.log('Connected to Room "%s"', room.name);

                // Log your Client's LocalParticipant in the Room
                const localParticipant = room.localParticipant;
                console.log(
                    `Connected to the Room as LocalParticipant "${localParticipant}"`
                );

                // Log any Participants already connected to the Room
                room.participants.forEach((participant) => {
                    console.log(`Participant "${participant}" is connected to the Room`);
                });

                // Log new Participants as they connect to the Room
                room.once("participantConnected", (participant) => {
                    console.log(
                        `Participant "${participant.identity}" has connected to the Room`
                    );
                });

                // Log Participants as they disconnect from the Room
                room.once("participantDisconnected", (participant) => {
                    console.log(
                        `Participant "${participant.identity}" has disconnected from the Room`
                    );
                    toast.error(`Votre correspondant s'est déconnecté.`);
                    if (room.name.search("/user") === -1) {
                        toast.info(
                            "Déconnection automatique : La salle était une salle sans rendez vous, votre correspondant ne pourra pas vous rejoindre."
                        );
                        closeCommunicationWindow();
                        room.localParticipant.videoTracks.forEach((publication) => {
                            publication.track.stop();
                        });
                        room.localParticipant.audioTracks.forEach((publication) => {
                            publication.track.stop();
                        });
                        room.disconnect();
                        setRoom(null);
                    }
                });

                room.once("trackUnpublished", (participant) => {
                    console.log(
                        `Participant "${participant.identity}" has disable its camera`
                    );
                    toast.error(`Votre correspondant à désactivé sa caméra`);
                });

                room.on("participantDisconnected", (participant) =>
                    console.log("disconnected", participant)
                );

                room.once("disconnected", (error) => {
                    room.participants.forEach((participant) =>
                        console.log("disconnected", participant)
                    );
                    setCommunicationOpen(false);
                });

                room.on("participantConnected", (participant) => {
                    console.log(`Participant "${participant.identity}" connected`);

                    participant.tracks.forEach((publication) => {
                        if (publication.isSubscribed) {
                            const track = publication.track;
                            document
                                .getElementById("remote-media")
                                .appendChild(track.attach());
                        }
                    });

                    participant.on("trackSubscribed", (track) => {
                        document
                            .getElementById("remote-media")
                            .appendChild(track?.attach());
                    });
                });

                room.participants.forEach((participant) => {
                    participant.tracks.forEach((publication) => {
                        if (publication.track) {
                            document
                                .getElementById("remote-media")
                                .appendChild(publication.track.attach());
                        }
                    });

                    participant.on("trackSubscribed", (track) => {
                        try {
                            document
                                .getElementById("remote-media")
                                .appendChild(track?.attach());
                        } catch (e) {
                        }
                    });
                });
            });
        });
    }

    // Cart management
    const [userId, setUserId] = useState(null);
    useEffect(() => {
        if (room) {
            const userId = extractUserId(room);
            setUserId(userId);
            getCart(userId);
        } else {
            setUserId(null);
            setCart({});
        }
    }, [room]);

    function extractUserId(room) {
        let user = room.name.split("/")[1];
        if (user.includes("user")) {
            user = user.replace("user", "");
        }
        console.log(user);
        return user;
    }

    const [cart, setCart] = useState({});

    function getCart(userId) {
        simpleFetch(`/carts/user/${userId}`, "GET", null, getToken()).then(
            (cart) => {
                console.log("HEY", cart);
                setCart(cart);
            }
        );
    }

    function addToCart(productId, quantity) {
        simpleFetch(
            `/carts/${cart.id}/product`,
            "POST",
            {
                productId: productId,
                amount: quantity,
            },
            getToken()
        ).then((cart) => {
            toast.success(`Panier mis à jour !`);
            toast.info(
                "Une modification n'apparait pas sur l'application du client ? Vous pouvez lui demander de rafraichir la page panier en tirant vers le bas."
            );

            getCart(userId);
        });
    }

    function removeFromCart(productId) {
        simpleFetch(
            `/carts/${cart.id}/product/${productId}`,
            "DELETE",
            null,
            getToken()
        ).then((cart) => {
            toast.success(`Panier mis à jour ! Produit supprimé`);
            toast.info(
                "Une modification n'apparait pas sur l'application du client ? Vous pouvez lui demander de rafraichir la page panier en tirant vers le bas."
            );

            getCart(userId);
        });
    }

    function updateCart(productId, quantity) {
        simpleFetch(
            `/carts/${cart.id}/product`,
            "POST",
            {
                productId: productId,
                amount: quantity,
            },
            getToken()
        ).then((cart) => {
            toast.success(`Panier mis à jour !`);
            toast.info(
                "Une modification n'apparait pas sur l'application du client ? Vous pouvez lui demander de rafraichir la page panier en tirant vers le bas."
            );

            getCart(userId);
        });
    }

    return (
        <CallContext.Provider
            value={{
                communicationOpen: communicationOpen,
                openCommunicationWindow: openCommunicationWindow,
                closeCommunicationWindow: closeCommunicationWindow,
                setRoom: setRoom,
                room: room,
                joinRoom: joinRoom,
                setLocalTrack: setLocalTrack,
                localTrack: localTrack,
                cart: cart,
                userId: userId,
                setCart: setCart,
                getCart: getCart,
                addToCart: addToCart,
                removeFromCart: removeFromCart,
                updateCart: updateCart,
            }}
        >
            {children}
        </CallContext.Provider>
    );
}

const useCalling = () => useContext(CallContext);

export {CallProvider, useCalling};
