import React from "react";
import App from "./App";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Login from "./routes/Login";
import { useAuthentification } from "./Context/AuthContext";
import NotificationView from "./routes/notifications/NotificationView";
import OrderManage from "./routes/orders/OrderManage/OrderManage";
import OneOrder from "./routes/orders/OneOrder";
import CategoriesList from "./routes/categories/CategoriesList";
import ProductList from "./routes/products/ProductList";
import AppointmentList from "./routes/telepharmacie/AppointmentList/AppointmentList";
import PaiementLinksView from "./routes/PaiementLinks/PaiementLinksView";
import Coupons from "./routes/coupons/Coupons";
function Title({ title }) {
  return (
    <main
      style={{
        padding: "1rem 0",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flex: 1,
        width: "100%",
      }}
    >
      <h2>{title}</h2>
      <Outlet />
    </main>
  );
}
function Router(props) {
  const { isAuthentificated } = useAuthentification();

  return (
    <BrowserRouter>
      {isAuthentificated ? (
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Title title={"Pharmacie"} />} />
            <Route path="cart" element={<ProductList />}>
              <Route path=":productId" element={<ProductList />}></Route>
            </Route>

            <Route path="orders" element={<OrderManage />} />
            <Route path="orders/:id" element={<OneOrder />} />

            <Route path="categories" element={<CategoriesList />}></Route>

            <Route path="products" element={<ProductList />}>
              <Route path=":productId" element={<ProductList />} />
            </Route>
            <Route path="telepharmacie" element={<AppointmentList />} />
            <Route
              path="utilisateurs"
              element={<Title title={"utilisateurs  /"} />}
            ></Route>
            <Route path="notifications" element={<NotificationView />} />
            <Route path="paiement" element={<PaiementLinksView />} />
            <Route path="coupons" element={<Coupons />} />
            <Route path="*" element={<Title title={"Page non trouvée."} />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Router;
